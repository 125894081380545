<input
  *ngIf="type != 'phone'"
  [type]="type"
  (change)="onChange($event)"
  (keyup)="onChange($event)"
  [value]="value"
  [max]="max"
  [min]="min"
  [pattern]="pattern"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [disabled]="isDisabled"
  [spellcheck]="spellcheck"
  [ngClass]="_touched && isError ? 'error' : ''"
  [ngStyle]="{ border: '1px solid #e0e0e0', 'text-align': align, color: isDisabled ? '#91979E' : '#1B1C1E' }"
  (blur)="onBlur()"
/>

<input
  *ngIf="type === 'phone'"
  appPhoneNumberFormat
  [type]="type"
  (change)="onChange($event)"
  (keyup)="onChange($event)"
  [value]="value"
  [max]="max"
  [min]="min"
  [pattern]="pattern"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [disabled]="isDisabled"
  [spellcheck]="spellcheck"
  [ngClass]="_touched && isError ? 'error' : ''"
  [ngStyle]="{ border: '1px solid #e0e0e0', 'text-align': align }"
  (blur)="onBlur()"
/>

<p *ngIf="_touched && errorMessage && isError" style="font-size: 12px; margin-bottom: 0px; margin-top: 5px">
  {{ errorMessage }}
</p>
