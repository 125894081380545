<div>
  <input
    type="radio"
    [name]="name"
    [value]="value"
    [(ngModel)]="modelValue"
    [disabled]="disabled"
    (change)="valueChanged(value)"
  />
  <label>
    <ng-content></ng-content>
  </label>
</div>
