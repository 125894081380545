import { User } from './job-content.model';
import { ImplementationType, JobStatusType, Location } from './job.model';

export type JobScheduleContents = {
  complexes: Complex[];
  houseDistricts: HouseDistrict[];
  senateDistricts: SenateDistrict[];
  locations: Location[];
  pcs: PC[];
  cmAssigned: { cmAssigned: User | null }[];
  implementationTypes: ImplementationType[];
  jobStatusTypes: JobStatusType[];
};

export type Complex = {
  id: string;
  name: string;
};

export type HouseDistrict = {
  id: string;
  rep: string;
  district: number;
};

export type SenateDistrict = {
  id: string;
  senator: string;
  district: number;
};

export type PC = {
  id: string;
  user: User;
};
