import { AdditionalInformationItem } from './alloment-request-id-request.model';

export interface AllotmentRequestNarrativeResponse {
  id: string;
  scope: {
    new: boolean;
    renovation: boolean;
    addition: boolean;
    replace: boolean;
    ongoing: boolean;
  };
  isAdditionalFund: boolean;
  additionalFund: string;
  isReappropriatedFund: boolean;
  reappropriatedFund: string;
  estimatedUsefulLife: number;
  problemNeed: string;
  alternativeConsider: string;
  primaryImprovement: string;
  ancillaryImprovement: string;
  futureOperatingCostImpact: string;
  note: string;
  additionalInformation: AdditionalInformationItem[];
  personsToCc: string[];
}
