import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomHeaderDateRangePickerComponent } from '@shared-component/custom-header-date-range-picker/custom-header-date-range-picker.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BannerNotificationComponent } from './component/banner-notification/banner-notification.component';
import { CheckboxFormComponent } from './component/checkbox-form/checkbox-form.component';
import { ConfirmDialogComponent } from './component/confirm-dialog/confirm-dialog.component';
import { Cpt1IframeComponent } from './component/cpt1-iframe/cpt1-iframe.component';
import { Cpt1RecordIframeComponent } from './component/cpt1-record-iframe/cpt1-record-iframe.component';
import { CurrencyMatFormFieldComponent } from './component/currency-mat-form-field/currency-mat-form-field.component';
import { CurrencyComponent } from './component/currency/currency.component';
import { CustomHeaderDatePickerComponent } from './component/custom-header-date-picker/custom-header-date-picker.component';
import { CustomSnackbarComponent } from './component/custom-snackbar/custom-snackbar.component';
import { DatePickerComponent } from './component/date-picker/date-picker.component';
import { DateTimeInputComponent } from './component/date-time-input/date-time-input.component';
import { DeleteRecordDialogComponent } from './component/delete-record-dialog/delete-record-dialog.component';
import { DeleteUserDialogComponent } from './component/delete-user-dialog/delete-user-dialog.component';
import { DialogComponent } from './component/dialog/dialog.component';
import { DownloadDialogComponent } from './component/download-dialog/download-dialog.component';
import { FilterChipsComponent } from './component/filter-chips/filter-chips.component';
import { FilterComponent } from './component/filter/filter.component';
import { ClickOutsideDirective } from './component/filter/filter.directive';
import { ImageBoxComponent } from './component/image-box/image-box.component';
import { InputComponent } from './component/input/input.component';
import { ListComponent } from './component/list/list.component';
import { MenuComponent } from './component/menu/menu.component';
import { MultiSelectComponent } from './component/multi-select/multi-select.component';
import { MultiSelectDirective } from './component/multi-select/multi-select.directive';
import { MultipageFormComponent } from './component/multipage-form/multipage-form.component';
import { NumberInputComponent } from './component/number-input/number-input.component';
import { PaneComponent } from './component/pane/pane.component';
import { RadioButtonComponent } from './component/radio-button/radio-button.component';
import { RedirectComponent } from './component/redirect/redirect.component';
import { SelectBoxComponent } from './component/select-box/select-box.component';
import { SpinnerOverlayComponent } from './component/spinner-overlay/spinner-overlay.component';
import { StatementBuilderComponent } from './component/statement-builder/statement-builder.component';
import { StatementInputComponent } from './component/statement-input/statement-input.component';
import { StatusWorkflowComponent } from './component/status-workflow/status-workflow.component';
import { CurrencyFormatDirective } from './currency-format/currency-format.directive';
import { PhoneNumberFormatDirective } from './directive/';
import { MaskDateDirective } from './directive/date-mask.directive';
import { FeatureFlagDirective } from './feature-flag/feature-flag.directive';
import { FeatureFlagDisabledDirective } from './feature-flag/feature-flag-disabled.directive';
import { CustomCurrencyPipe } from './pipe/currency-pipe';
import { CustomPercentagePipe } from './pipe/percentage-amount.pipe';
import { CustomPhoneNumberPipe } from './pipe/phone-number-pipe';

// todo: This is a prototype module for sharing components between modules.
@NgModule({
  declarations: [
    MenuComponent,
    ListComponent,
    SpinnerOverlayComponent,
    ImageBoxComponent,
    FeatureFlagDirective,
    FeatureFlagDisabledDirective,
    FilterComponent,
    DialogComponent,
    MultipageFormComponent,
    Cpt1IframeComponent,
    RedirectComponent,
    InputComponent,
    Cpt1RecordIframeComponent,
    CurrencyComponent,
    SelectBoxComponent,
    DatePickerComponent,
    DateTimeInputComponent,
    RadioButtonComponent,
    CheckboxFormComponent,
    NumberInputComponent,
    DownloadDialogComponent,
    CurrencyFormatDirective,
    ClickOutsideDirective,
    DeleteRecordDialogComponent,
    DeleteUserDialogComponent,
    MultiSelectComponent,
    MultiSelectDirective,
    PhoneNumberFormatDirective,
    MaskDateDirective,
    ConfirmDialogComponent,
    StatementBuilderComponent,
    CustomSnackbarComponent,
    CurrencyMatFormFieldComponent,
    CustomCurrencyPipe,
    FilterChipsComponent,
    CustomHeaderDatePickerComponent,
    CustomHeaderDateRangePickerComponent,
    PaneComponent,
    CustomPhoneNumberPipe,
    CustomPercentagePipe,
    StatusWorkflowComponent,
    StatementInputComponent,
    BannerNotificationComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSortModule,
    MatInputModule,
    MatNativeDateModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatDialogModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
    FormsModule,
    MatRadioModule,
    MatDatepickerModule,
    FormsModule,
    MatTooltipModule,
    MatChipsModule,
    NgxMatSelectSearchModule,
    MatSelectInfiniteScrollModule,
    NgxFileDropModule,
  ],
  exports: [
    MenuComponent,
    ListComponent,
    SpinnerOverlayComponent,
    ImageBoxComponent,
    FeatureFlagDirective,
    FeatureFlagDisabledDirective,
    FilterComponent,
    DialogComponent,
    MultipageFormComponent,
    InputComponent,
    CurrencyComponent,
    SelectBoxComponent,
    DatePickerComponent,
    DateTimeInputComponent,
    RadioButtonComponent,
    CheckboxFormComponent,
    Cpt1RecordIframeComponent,
    NumberInputComponent,
    CurrencyFormatDirective,
    ClickOutsideDirective,
    MultiSelectComponent,
    PhoneNumberFormatDirective,
    MaskDateDirective,
    StatementBuilderComponent,
    CurrencyMatFormFieldComponent,
    CustomCurrencyPipe,
    FilterChipsComponent,
    PaneComponent,
    CustomPhoneNumberPipe,
    NgxMatSelectSearchModule,
    CustomPercentagePipe,
    StatusWorkflowComponent,
    MatSelectInfiniteScrollModule,
    StatementInputComponent,
    NgxFileDropModule,
    BannerNotificationComponent,
  ],
  providers: [{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }],
})
export class SharedModule {}
