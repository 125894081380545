export interface LocationCreateRequest {
  overview: LocationOverview;
  demographic: LocationDemographic;
  taxMapKeys: Array<LocationTaxMapKey>;
  photos: LocationPhoto[];
}

export interface LocationOverview {
  code: string;
  status: boolean;
  facilityTypeId: string;
  cipEligible: boolean;
  facilitiesUseEligible: boolean;
  serviceEligible: boolean;
  legislatureHouseId: string;
  legislatureSenateId: string;
  name: string;
  shortName: string;
  legalName: string;
  schoolImpactFeeDistrictId: string;
  districtId: string;
  complexAreaId: string;
  complexId: string;
  islandId: string;
  jobDistrictComplexId: string;
  bipsDistrictId: string;
  schoolTypeId: string;
}

export interface LocationDemographic {
  address: string;
  city: string;
  zip: string;
  geoLongitude: number;
  geoLatitude: number;
  principalName: string;
  principalPhone: string;
  principalFax: string;
  website: string;
  charterInd: boolean;
  gradeFrom: string;
  gradeTo: string;
  floodZone: string;
  sma: string;
  specDistrict: string;
  evac: string;
  zoning: string;
  historic: string;
  archaeology: string;
  propertyOwnedBy: string;
  landIssues: string;
  other: string;
}

export interface LocationPhoto {
  id?: string;
  pictureFileName: string;
  filePath: string;
}

export interface LocationTaxMapKey {
  partialTmk: string;
  cededLandStatusId: string;
}
