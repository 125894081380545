<div *ngIf="displayBanner">
  <div
    *ngFor="let message of messages"
    class="notification-banner"
    [style.background]="background"
    [style.border-color]="borderColor"
  >
    <p class="notification-message" [style.color]="textColor">{{ message }}</p>
  </div>
</div>
