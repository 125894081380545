<button mat-icon-button [matMenuTriggerFor]="menu" onclick="event.stopPropagation()">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngFor="let option of options">
    <button mat-menu-item (click)="handleMenuSelect(option.value)">
      <span>{{ option.label }}</span>
    </button>
  </ng-container>
</mat-menu>
