import { ValueOption } from '../interface';
import { CededLandStatus } from './location-option.model';

export interface LocationIdResponse {
  overview: {
    id: string;
    status: boolean;
    code: string;
    name: string;
    shortName: string;
    legalName: string;
    cipEligible: boolean;
    facilitiesUseEligible: boolean;
    serviceEligible: boolean;
    facilityType: ValueOption;
    schoolType: ValueOption;
    legislatureHouse: LegislatureHouse;
    legislatureSenate: LegislatureSenate;
    schoolImpactFeeDistrict: ValueOption;
    complexArea: ComplexArea;
    complex: ValueOption;
    island: ValueOption;
    jobDistrictComplexCode: JobDistrictComplexCode;
    bipsDistrict: ValueOption;
    district: ValueOption;
  };
  demographic?: Demographic;
  taxMapKeys?: Array<TaxMapKey>;
  photos?: Photo[];
}

export interface Photo {
  id: string;
  pictureFileName: string;
  pictureFilePath: string;
}

export interface TaxMapKey {
  id: string;
  partialTmk: string;
  cededLandStatus: CededLandStatus;
}

export interface Demographic {
  address: string;
  city: string;
  zipcode: string;
  geoLongitude: string;
  geoLatitude: string;
  principal: Principal;
  website: string;
  charterInd: boolean;
  gradeFrom: string;
  gradeTo: string;
  floodZone: string;
  sma: string;
  specDistrict: string;
  evac: string;
  zoning: string;
  historic: string;
  archaeology: string;
  propertyOwnedBy: string;
  landIssues: string;
  other: string;
}

export interface Principal {
  id: string;
  name: string;
  phone: string;
  fax: string;
}

export interface JobDistrictComplexCode {
  code: string;
  description: string;
  jobComplexDescription: string;
}

export interface ComplexArea {
  id: string;
  name: string;
  code: string;
}

export interface LegislatureSenate {
  id: string;
  district: string;
  senator: string;
}

export interface LegislatureHouse {
  id: string;
  district: string;
  rep: string;
}
