import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-image-box',
  templateUrl: './image-box.component.html',
  styleUrls: ['./image-box.component.scss'],
})
export class ImageBoxComponent implements OnInit {
  @Input() src!: SafeUrl;
  @ViewChild('container') container!: ElementRef;
  @ViewChild('image') image!: ElementRef;

  get containerRect() {
    return this.container.nativeElement.getBoundingClientRect();
  }

  get imageRect() {
    return this.image.nativeElement.getBoundingClientRect();
  }

  scale = 1;
  offsetX = 0;
  offsetY = 0;
  isPanning = false;
  panStart = { x: 0, y: 0 };

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  /**
   * When image loads, center it in the container.
   */
  onLoadImage() {
    this.offsetX = (this.container.nativeElement.clientWidth - this.image.nativeElement.clientWidth) / 2;
    this.transformImage();
  }

  /**
   * Sets the starting point where the grab started, sets the flag that indicates the image is panning, and changes the
   * cursor type to show the image is being grabbed.
   */
  onMouseDown(e: MouseEvent) {
    e.preventDefault();
    this.renderer.setStyle(this.container.nativeElement, 'cursor', 'grabbing');
    this.panStart.x = e.clientX - this.offsetX;
    this.panStart.y = e.clientY - this.offsetY;
    this.isPanning = true;
  }

  /**
   * If the image is panning, sets the offset x and y values, then runs transformImage to move the image on screen.
   */
  onMouseMove(e: MouseEvent) {
    e.preventDefault();
    if (this.isPanning) {
      this.offsetX = e.clientX - this.panStart.x;
      this.offsetY = e.clientY - this.panStart.y;
      this.transformImage();
    }
  }

  /**
   * If the image is panning, clears the panning flag and changes the cursor type to show the image was released.
   */
  onMouseUp(e: MouseEvent) {
    e.preventDefault();
    if (this.isPanning) {
      this.renderer.setStyle(this.container.nativeElement, 'cursor', 'grab');
      this.isPanning = false;
    }
  }

  /**
   * Determines whether image should zoom in or out based on the direction the wheel is scrolling (deltaY). Determines
   * the relative coordinate of the mouse on screen by factoring in the location of the container element. Then, calls
   * changeZoom to adjust the zoom around the coordinate one tick.
   */
  onWheel(e: WheelEvent) {
    e.preventDefault();
    const zoomIn = e.deltaY < 0;
    const { left, top } = this.containerRect;
    const relativeMouseX = e.clientX - left;
    const relativeMouseY = e.clientY - top;
    this.changeZoom(relativeMouseX, relativeMouseY, zoomIn);
  }

  /**
   * Increase the size of the image
   */
  zoomIn() {
    const { x, y } = this.findCenter();
    return this.changeZoom(x, y, true);
  }

  /**
   * Decrease the size of the image
   */
  zoomOut() {
    const { x, y } = this.findCenter();
    return this.changeZoom(x, y, false);
  }

  /**
   * Updates the image's offset and scale according to the instance's values.
   */
  private transformImage() {
    const value = `translate(${this.offsetX}px, ${this.offsetY}px) scale(${this.scale})`;
    this.renderer.setStyle(this.image.nativeElement, 'transform', value);
  }

  /**
   * Returns the coordinate for the center of the container.
   */
  private findCenter() {
    const container = this.containerRect;
    let x = (container.left + container.width) / 2;
    let y = (container.top + container.height) / 2;
    return { x, y };
  }

  /**
   *
   * @param x {number} coordinate that the image should zoom around
   * @param y {number} coordinate that the image should zoom around
   * @param zoomIn {boolean} flag indicating whether to zoom in (true) or out (false).
   * @private
   */
  private changeZoom(x: number, y: number, zoomIn: boolean) {
    const dx = (x - this.offsetX) / this.scale;
    const dy = (y - this.offsetY) / this.scale;
    this.scale = zoomIn ? this.scale * 1.2 : this.scale / 1.2;
    this.offsetX = x - dx * this.scale;
    this.offsetY = y - dy * this.scale;
    this.transformImage();
  }
}
