import {
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-multipage-form',
  templateUrl: './multipage-form.component.html',
  styleUrls: ['./multipage-form.component.scss'],
})
export class MultipageFormComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
