import { DisplayOption } from '../interface';

export interface ProjectCategoryOption {
  id: string;
  code: string;
  description: string;
  descriptionRm: string;
  descriptionCp: string;
  active: boolean;
  projectCategoryGroupingId: string;
  projectPriorityId: string;
  externalId: number;
}

export interface ProjectOption {
  bfys: Array<DisplayOption>;
  projectCategories: ProjectCategoryOption[];
  projectTypes: Array<DisplayOption>;
  implementationTypes: Array<DisplayOption>;
  projectStatuses: Array<DisplayOption>;
  islands: Array<DisplayOption>;
  doeDistricts: Array<DisplayOption>;
  complexes: Array<DisplayOption>;
  houseDistricts: Array<DisplayOption>;
  senateDistricts: Array<DisplayOption>;
  csvFields: Array<DisplayOption>;
  csRequireds: Array<DisplayOption>;
  fundingAssessments: Array<DisplayOption>;
  statusVoidReasons: Array<DisplayOption>;
  categories: Array<DisplayOption>;
  subcategories: Array<DisplayOption>;
  mofs: Array<DisplayOption>;
  projectPriorities: Array<DisplayOption>;
  projectSources: Array<DisplayOption>;
  arBatchMonths: Array<DisplayOption>;
  units: Array<DisplayOption>;
  famProjectPriorities: [
    {
      id: string;
      code: number;
      description: string;
      externalId: number;
    },
  ];
  famProjectScores: [
    {
      id: string;
      code: number;
      description: string;
      externalId: number;
    },
  ];
  uniformatLevels: [
    {
      id: string;
      code: string;
      description: string;
      externalId: number;
    },
  ];
  appropriationGroups: [
    {
      value: string;
      displayLabel: string;
      locationId: string;
    },
  ];
  locations: Array<DisplayOption>;
  planners: Array<DisplayOption>;
  projectWorkPrograms: { id: string; projectCategoryId: string; projectTypeId: string }[];
  projectMofs: { id: string; name: string }[];
}
