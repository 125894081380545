<div fxLayout="column" [ngStyle]="{ padding: padding, height: '100%' }">
  <div
    *ngIf="draggable"
    fxLayout="row"
    style="align-items: center; cursor: move"
    mat-dialog-title
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
  >
    <ng-container [ngTemplateOutlet]="titleSectionTemplate"></ng-container>
  </div>
  <div *ngIf="!draggable" fxLayout="row" style="align-items: center">
    <ng-container [ngTemplateOutlet]="titleSectionTemplate"></ng-container>
  </div>

  <ng-template #titleSectionTemplate>
    <div style="width: 90%">
      <div [ngStyle]="{ padding: titlePadding }">
        <div class="flex">
          <span
            style="font-size: 18px; font-weight: 600; margin-right: 10px; cursor: default; user-select: text"
            (mousedown)="stopPropagation($event)"
          >
            {{ dialogTitle }}
          </span>
          <span
            class="status"
            *ngIf="status"
            [ngClass]="{
              'status-progress': status === 'In Progress',
              'status-batch': status !== 'In Progress',
              'status-lapsed': status === 'Lapsed'
            }"
          >
            {{ status }}
          </span>
          <ng-container *ngIf="showEditButton && iconName">
            <div class="edit-mode-button" (click)="handleEventIcon($event)">
              <span style="margin-right: 4px; color: #2b6da3">{{ editText }}</span>
              <mat-icon style="color: #2b6da3; font-size: 18px">{{ iconName }}</mat-icon>
            </div>
          </ng-container>
          <div *ngIf="banner?.text">
            <div [ngClass]="['banner', banner.class]">
              <span>{{ banner.text }}</span>
            </div>
          </div>
        </div>
        <div
          class="flex"
          style="cursor: default; user-select: text; flex-wrap: wrap"
          (mousedown)="stopPropagation($event)"
        >
          <div *ngFor="let item of keyIdentifiers" class="key">
            <span class="label">{{ item.label }}:</span>
            <span class="value">
              {{ item.value }}
            </span>
            <mat-icon
              *ngIf="item.toolTip"
              class="field-info-icon"
              [matTooltip]="item.toolTip"
              matTooltipPosition="right"
            >
              info
            </mat-icon>
          </div>
        </div>
      </div>
    </div>
    <button mat-icon-button style="margin-left: auto" (click)="close()"><mat-icon>close</mat-icon></button>
  </ng-template>
  <ng-content></ng-content>
</div>
