import { formatNumber } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
@Directive({
  selector: 'input[appCurrencyFormat]',
  providers: [
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: CurrencyFormatDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyFormatDirective),
      multi: true,
    },
  ],
})
export class CurrencyFormatDirective {
  private _value: string | null;

  constructor(private elementRef: ElementRef<HTMLInputElement>, private control: NgControl) {}

  ngOnInit() {
    this.control.control?.valueChanges.subscribe(value => {
      if (value === null) {
        this._value = '0.00';
      } else {
        if (value !== 0) {
          this._value = value.toString().replace(/[^\d.-]/g, '');
        }
      }
      this._onChange(this._value);
    });
  }

  get value(): string | null {
    return this._value;
  }

  @Input('value')
  set value(value: string | null) {
    this._value = value;
    this.formatValue(value);
  }

  private formatValue(value: string | null) {
    if (value || value === '0') {
      /*
        ^-?[0-9]\d*(\.\d+)?$ : Decimal Number Regex (allow negative number)
      */
      if (/^-?[0-9]\d*(\.\d+)?$/.test(value)) {
        this.elementRef.nativeElement.value =
          Number(value) >= 0
            ? formatNumber(Number(value), 'en-US', '1.2-2')
            : `(${formatNumber(Number(value) * -1, 'en-US', '1.2-2')})`;
      } else {
        this.elementRef.nativeElement.value = value;
      }
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private unFormatValue() {
    let value = this.elementRef.nativeElement.value;

    // Remove parentheses and add minus character for negative amount
    if (value.charAt(0) === '(') {
      value = '-' + value.substring(1, value.length - 1);
    }

    this._value = value.replace(/[^\d.-]/g, '');
    if (value) {
      this.elementRef.nativeElement.value = this._value;
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this._value = value.replace(/[^\d.-]/g, '').replace(/^(-)|-+/g, '$1');
    this._onChange(this._value);
  }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(this._value);
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue();
  }

  _onChange(value: any): void {
    this.elementRef.nativeElement.value = value;
  }

  writeValue(value: any) {
    this._value = value;
    this.formatValue(this._value);
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {}
}
