export interface AllotmentRequestIdRequest {
  jobId?: string;
  status: string;
  allotmentRequestInformation: {
    id: string;
    legislativeTitle?: string;
    legislativeDescription: string;
    projectDescription: string;
    capitalProjectNo: string;
    fiscalMonth: number;
    arPriority: number;
    preArPriority: number;
  };
  allotmentRequestNarrative: {
    id: string;
    scope: ScopeProject;
    isAdditionalFund: true;
    additionalFund: string;
    isReappropriatedFund: true;
    reappropriatedFund: string;
    estimatedUserfulLife: number;
    problemNeed: string;
    alternativeConsider: string;
    primaryImprovement: string;
    ancillaryImprovement: string;
    futureOperatingCostImpact: string;
    note: string;
    additionalInformation: AdditionalInformationItem[];
    personsToCc: string[];
  };
  allotmentRequestFunding: {
    id: string;
    planConsultant: number;
    planStaff: number;
    landLand: number;
    landConsultant: number;
    landStaff: number;
    designConsultant: number;
    designStaff: number;
    buildingBid: number;
    buildingAlternates: number;
    buildingContingency: number;
    utilities: number;
    utilitiesContingency: number;
    constructionConsultant: number;
    constructionManagement: number;
    constructionStaff: number;
    woaAmount: number;
    isWoaLess: number;
    woaReason: string;
    equipmentAmount: number;
    comments: {};
    fundingCostConstructions: FundingCostConstruction[];
    fundingCostDesigns: FundingCostDesign[];
  };
  allotmentRequestDate: {
    id: string;
    bidAwardOption: string;
    bidAwardDate: string;
    designStartOption: string;
    designStartDate: string;
    designEndOption: string;
    designEndDate: string;
    constructStartOption: string;
    constructStartDate: string;
    constructEndOption: string;
    constructEndDate: string;
  };
}

export interface FundingCostDesign {
  id?: string;
  locationId: number;
  jobId: number;
  design: number;
}

export interface FundingCostConstruction {
  id?: string;
  locationId: number;
  jobId: number;
  construction: number;
  qualifyingProject: number;
}

export interface ScopeProject {
  new: boolean;
  renovation: boolean;
  addition: boolean;
  replace: boolean;
  ongoing: boolean;
}

export enum AdditionalInformationKey {
  APPROPRIATION_CATEGORY = 'appropriation_category',
  PROBLEM_NEED = 'problem_need',
  IMPROVEMENTS = 'improvements',
  ALTERNATIVE_CONSIDER = 'alternative_consider',
  ART_FUNDS = 'art_funds',
  FISCAL_YEAR = 'fiscal_year',
}

export interface AdditionalInformationItem {
  statement: string | null;
  key?: AdditionalInformationKey;
}
