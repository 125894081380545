import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Options } from 'app/shared/interface';

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectBoxComponent),
    },
  ],
  selector: 'app-select-box',
  templateUrl: './select-box.component.html',
  styleUrls: ['./select-box.component.scss'],
})
export class SelectBoxComponent implements ControlValueAccessor {
  @Input() public placeholder: string;
  @Input() public options: Options[];
  @Input() public text: string;
  @Input() public isDisabled: boolean = false;

  selectedIndex: number = 0;

  valueChanged(value: any) {
    console.log('onHandleSelect :>> ', value);
    this.onChange(value);
    this.onTouched();
  }

  // CVA implementation

  public onChange = (_: any) => {};
  public onTouched = () => {};

  // register onChange which we will call when the selected value is changed
  // so that the value is passed back to the form model
  public registerOnChange(fn: any): void {
    console.log('registerOnChange :>> ', fn);
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // sets the selected value based on the corresponding form model value
  public writeValue(value: any): void {
    console.log('selectedIndex :>> ', value);
    this.selectedIndex = value;
  }
}
