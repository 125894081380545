import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface MenuOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() options: MenuOption[] = [];
  @Output() menuSelect: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  handleMenuSelect(option: string) {
    this.menuSelect.emit(option);
  }
}
