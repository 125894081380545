import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ContractDesignPacketToPhase } from 'app/shared/models';

@Component({
  selector: 'app-statement-input',
  templateUrl: './statement-input.component.html',
  styleUrls: ['./statement-input.component.scss'],
})
export class StatementInputComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges {
  @Input() isEditing: boolean;
  @Input() isNew: boolean;
  @Input() statement: string;
  @Input() isAgDocsSection: boolean = false;
  @Input() checkboxValue: boolean = false;
  @Input() isChildStatement: boolean = false;
  @Input() checkedPhases: ContractDesignPacketToPhase[];
  @Input() phase: ContractDesignPacketToPhase;
  @Input() blockedPacket: boolean;
  @Output() save: EventEmitter<string> = new EventEmitter();
  @Output() remove: EventEmitter<any> = new EventEmitter();
  @Output() add: EventEmitter<any> = new EventEmitter();
  @Output() move: EventEmitter<ContractDesignPacketToPhase> = new EventEmitter();
  @Output() check: EventEmitter<MatCheckboxChange> = new EventEmitter();
  @ViewChild('statement') statementInput: ElementRef;

  isEditingStatement: boolean = false;
  isNewlyStatement: boolean;
  showRedBorder: boolean = false;

  isSetStatement: boolean = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checkedPhases']?.currentValue) {
      this.checkedPhases = this.checkedPhases.filter(phase => phase.id !== this.phase.id);
    }
  }

  ngAfterViewChecked(): void {
    if (this.statementInput && !this.isSetStatement) {
      this.statementInput.nativeElement.value = this.statement;
      this.isSetStatement = true;
    }
  }

  ngAfterViewInit(): void {
    // this.statementInput.nativeElement.value = this.statement;
  }

  ngOnInit(): void {
    if (!this.statement) {
      this.isEditingStatement = true;
      this.isNewlyStatement = true;
    }
  }

  onEdit(): void {
    this.isEditingStatement = true;
  }

  onCancel(): void {
    if (!this.isNewlyStatement) {
      this.isEditingStatement = false;
      this.statementInput.nativeElement.value = this.statement;
      this.isSetStatement = false;
    } else {
      this.remove.emit();
    }
  }

  onSave(): void {
    if (this.statementInput.nativeElement.value === '') {
      this.showRedBorder = true;
    } else {
      this.save.emit(this.statementInput.nativeElement.value);
    }
  }

  onRemove(): void {
    this.remove.emit();
  }

  getLeftStyle(): string {
    if (!this.isAgDocsSection) return '5%';
    else {
      if (!this.isEditing && !this.isNew) return '10%';
      else return '8%';
    }
  }

  onAdd(): void {
    this.add.emit();
  }

  onMove(destinationPhase: ContractDesignPacketToPhase): void {
    this.move.emit(destinationPhase);
  }

  onChangeCheckbox(event: MatCheckboxChange): void {
    this.check.emit(event);
  }
}
