export interface JOCContractIdRequest {
  contractNo: string;
  contractName: string;
  statusId: string;
  executedDate: string;
  endDate: string;
  vendorId: string;
  extAllowedPerContract: string;
  comment: string;
  contactName1: string;
  contactEmail1: string;
  contactCompanyPos1: string;
  contactName2: string;
  contactEmail2: string;
  contactCompanyPos2: string;
  districtId: string;
  ifbNumber: string;
  jocContractTypeId: string;
  pointOfContactName: string;
  pointOfContactPhone: string;
}

export interface JOCOverViewFormRequest {
  contractNo: string;
  district: string;
  contractName: string;
  ifbNumber: string;
  status: string;
  typeofContract: string;
  contractExecutedDate: string;
  pointOfContactName: string;
  contractEndDate: string;
  pointOfContactPhone: string;
  vendor: string;
  ofExtAllowedPerContract: string;
  comment: string;
  name1: string;
  companyPosition1: string;
  emailAddress1: string;
  name2: string;
  companyPosition2: string;
  emailAddress2: string;
}
