export enum ContractIsEffective {
  UponIssuanceOfNoticeToProceed = '1',
  Other = '2',
}

export enum DesignContractPacketSectionEnum {
  GeneralInformation = 'general-information',
  AgDocs = 'ag-docs',
  Attachments = 'attachments',
  PcChecklist = 'pc-checklist',
  ContractForProfessionalService = 'contract',
}

export enum GeneralInformationTabIndexEnum {
  ContractInfor = 0,
  Summary = 1,
}

export enum AgDocsTabIndexEnum {
  ScopeOfServices = 0,
  CompensationPaymentSchedule = 1,
  TimeSchedule = 2,
  SpecialConditions = 3,
}
