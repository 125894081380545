import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from 'aws-amplify';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Amplify.configure({
  region: 'us_west_2',
  userPoolId: environment.cognitoUserPoolId,
  userPoolWebClientId: environment.cognitoClientId,
  //authenticationFlowType: 'USER_PASSWORD_AUTH',
  oauth: {
    domain: environment.cognitoDomain,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${location.origin}/homepage`,
    redirectSignOut: `https://${environment.auth0TenantId}.us.auth0.com/v2/logout?returnTo=${location.origin}/login&client_id=${environment.auth0ClientId}`, //`${location.origin}/logout`,
    responseType: 'code',
  },
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
