import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ability, PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import { NgxClarityModule } from 'ngx-clarity';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplicationConfig } from './global/config/application.config';
import { ErrorInterceptor } from './global/interceptors/error.interceptor';
import { WithCredentialsInterceptor } from './global/interceptors/with-credentials.interceptor';
import { SharedModule } from './shared/shared.module';
import { UserInterceptor } from './user/user.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AbilityModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexModule,
    SharedModule,
    MatSnackBarModule,
    NgxClarityModule.forRoot({
      enabled: true,
      projectId: environment.clarityProjectId,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [ApplicationConfig],
      useFactory: (appConfig: ApplicationConfig) =>
        function (): Observable<any> {
          return appConfig.loadConfig();
        },
      multi: true,
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {}
