import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, finalize, map } from 'rxjs';
import { SpinnerOverlayService } from '../../shared/component/spinner-overlay/spinner-overlay.service';
import { SnackBarService } from './snack-bar.service';

export interface GetAllApplicationNotificationsResponse {
  messages: string[];
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private http: HttpClient,
    private spinner: SpinnerOverlayService,
    private snackBarService: SnackBarService,
  ) {}

  getAllApplicationNotifications() {
    const url = '/api/v1/notification/all';
    this.spinner.show();

    return this.http.get(url).pipe(
      catchError(err => {
        this.snackBarService.open({ message: 'Unable to get application notifications' });
        return err;
      }),
      map((response: any) => {
        return response.data;
      }),
      finalize(() => this.spinner.hide()),
    );
  }
}
