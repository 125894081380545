import { Component, EventEmitter, forwardRef, Input, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputComponent),
    },
  ],
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements ControlValueAccessor {
  @Input() isDisabled: boolean = false;
  @Input() max: number;
  @Input() min: number;
  @Input() pattern: string;
  @Input() placeholder: string = '';
  @Input() readonly: boolean = false;
  // @Input() size: number = 0;
  @Input() spellcheck: boolean;
  @Input() errorMessage: string;
  @Input() isError: boolean;
  @Input() touched: boolean;
  @Input() type: string = 'text';
  @Input() align: string = 'left';

  @Output('change') change = new EventEmitter<string>();

  value: string = '';
  _touched: boolean = false;

  changed: (value: any) => void = (_: any) => {
    this.change.emit(this.value);
  };

  onTouched: () => void = () => {};

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: string): void {
    if (value === '') {
      this.value = '';
      return;
    }
    if (!value) {
      return;
    }
    if (typeof value !== 'string') {
      throw new Error('Expected value to be typeof string.');
    } else if (value) {
      this.value = value;
    }
  }

  onChange(event: any): void {
    if (this.isDisabled) return;
    this.changed(event.target.value);
    this.value = event.target.value;
  }

  onBlur(): void {
    this._touched = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['touched']) {
      this._touched = changes['touched'].currentValue;
    }

    if (changes['isError']) {
      this.isError = changes['isError'].currentValue;
    }
  }
}
