// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'development',
  production: false,
  maintenanceMode: false,
  features: [], //'procore'
  url: 'https://dev.hidoefacilities.org',
  apiUrl: 'https://api.dev.hidoefacilities.org',
  cognitoDomain: 'cpt-userpool-dev.auth.us-west-2.amazoncognito.com',
  cognitoUserPoolId: 'us-west-2_P3NA90s4c',
  cognitoClientId: 'dbgg6l3dukg6mt97bnt7ldo53',
  auth0TenantId: 'dev-58cds8tb4yeta8pm',
  auth0ClientId: '5KPtZbv0YqQAXVYIeoUEoqqmCNGv7mHx',
  procoreUrl: 'https://sandbox.procore.com/4063550/company/home/list',
  procoreAuthUrl: 'https://login-sandbox.procore.com/oauth',
  procoreApiUrl: 'https://sandbox.procore.com/rest/v1.0',
  procoreClientId: '8b8a94db845ae96bf6e7a95bbaaaf5cb8219a51308b63083a32f0210eab9d077',
  procoreClientSecret: 'de503736f36cb02e8706c19a328c6c0065e4da00728cb7e2388b9b8d741c09cd',
  cpt1Domain: 'gems.datahouse.com',
  cpt1Logout: 'https://c0abw752.caspio.com/folderlogout',
  ssoEnabled: true,
  clarityProjectId: 'gk3bk2yeds',
  dynatraceSrc: 'https://js-cdn.dynatrace.com/jstag/147f273fa2a/bf08103qjn/7698ff871295b246_complete.js',
  portalUrl: 'https://gems.datahouse.com/',
  quicksightEmbedUrl: '',
  quicksightLoginUrl: '',
  doeFacilitiesPortal: 'https://gems.datahouse.com',
  cspUrl: 'https://dev.csp.hidoefacilities.org',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
