export interface AppropriationGroupsResponse {
  id: string;
  bfy: string;
  actSectionItem: string;
  lapsedDate: string;
  school: string;
  appropriationType: string;
  appropriationCategory: string;
  hasAllotment: string;
  appropriations: Array<AppropriationModel>;
}

export interface AppropriationModel {
  id: string;
  appropriationKey: string;
  appropriationAmount: number;
  costType: string;
  afmsProgramId: string;
}
