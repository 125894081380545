<div fxLayout="column" fxLayoutGap="24px" [ngStyle]="{ padding: padding, width: width }">
  <div fxLayout="row" style="align-items: center">
    <span style="font-size: 18px; font-weight: 600" [ngStyle]="{ padding: titlePadding }">Download</span>
    <button mat-icon-button style="margin-left: auto" (click)="close()"><mat-icon>close</mat-icon></button>
  </div>
  <form [formGroup]="form" id="form" style="max-height: calc(100vh - 210px); overflow-y: auto">
    <div fxLayout="column" fxLayoutAlign="space-evenly start">
      <span class="form-field-label" style="font-weight: 500; margin-bottom: 13.3px">
        Select an option to download:
      </span>
      <mat-radio-group aria-label="Select an option to download:" formControlName="selectedOption">
        <div fxLayout="column" style="margin-bottom: 20px">
          <mat-radio-button value="allData">All data</mat-radio-button>
        </div>
        <div fxLayout="column" style="margin-bottom: 20px">
          <mat-radio-button value="pageView">Page view</mat-radio-button>
        </div>
        <div fxLayout="column" style="margin-bottom: 15.5px">
          <mat-radio-button value="custom">Custom</mat-radio-button>
        </div>
      </mat-radio-group>
      <div
        fxLayout="column"
        *ngIf="this.toggleCustomOptions === true"
        class="custom-view"
        [ngStyle]="{ height: data.numOptionsPerColumn ? (560 * data.numOptionsPerColumn) / 14 + 'px' : '560px' }"
      >
        <div class="item" *ngFor="let customOption of customOptionsList">
          <mat-checkbox [formControlName]="customOption.optionName">
            {{ customOption.optionDisplayLabel }}
          </mat-checkbox>
        </div>
      </div>
      <mat-checkbox fxLayout="column" formControlName="applyFilters" style="margin-bottom: 10px">
        Apply Filters
      </mat-checkbox>
    </div>
  </form>
  <div fxLayout="row" fxLayoutGap="16px" style="justify-content: space-between; margin-top: 1em">
    <button mat-stroked-button class="action-button" type="button" color="primary" (click)="close()">Cancel</button>
    <button mat-flat-button class="action-button" type="submit" color="primary" (click)="onSubmit()">Download</button>
  </div>
</div>
