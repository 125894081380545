import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: './delete-user-dialog.component.html',
  styleUrls: ['./delete-user-dialog.component.scss'],
})
export class DeleteUserDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      callBackDelFunction: any;
    },
    private dialogRef: MatDialogRef<DeleteUserDialogComponent>,
  ) {}

  ngOnInit() {}

  close(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    this.data.callBackDelFunction().subscribe((res: any) => {
      this.dialogRef.close(res);
    });
  }
}
