import { WorkCapabilities } from './contract-psc-id-response.model';

export interface PSCContractIdRequest {
  overview: overviewRequest;
  offeror: offerorRequest;
  workCapabilities: WorkCapabilities;
}

export interface overviewRequest {
  statusId: string;
  vendorId: string;
  executedDate: string;
  endDate: string;
  maximumAmount: number;
  minimumAmount: number;
  extAllowedPerContract: string;
  comment: string;
  contactName1: string;
  contactEmail1: string;
  contactCompanyPos1: string;
  contactNumber1: string;
  contactName2: string;
  contactEmail2: string;
  contactCompanyPos2: string;
  contactNumber2: string;
}

export interface offerorRequest {
  contractPscWorkLocations: [
    {
      contractPscLocationsCapacity: string;
      contractPscLocationsId: string;
    },
  ];
  locationsWorkers: string;
  contractPscLicenseTypeIds: [string];
  licenseTypesOther: string;
  licenseDetails: string;
  baseyardCapabilities: string;
  workHours: string;
}
