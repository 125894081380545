export interface AllotmentRequestDateResponse {
  id: string;
  bidAwardOption: string;
  bidAwardDate: string;
  designStartOption: string;
  designStartDate: string;
  designEndOption: string;
  designEndDate: string;
  constructStartOption: string;
  constructStartDate: string;
  constructEndOption: string;
  constructEndDate: string;
}
