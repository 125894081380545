export interface ARPendingSubmitNode {
  id: string;
  bfy?: string;
  school?: string;
  jobNumber?: string;
  jobName?: string;
  allotmentStatus?: string;
  packetBatchMonth?: string;
  numberOfJobInclude?: number;
  arSubmittedDate?: string;
  allotmentRequestId?: string;
  allotmentSummaryId?: string;
  parentId: string;
  children?: ARPendingSubmitNode[];
}
