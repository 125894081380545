import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SpinnerOverlayService } from '../spinner-overlay/spinner-overlay.service';

@Component({
  selector: 'app-cpt1-iframe',
  templateUrl: './cpt1-iframe.component.html',
  styleUrls: ['./cpt1-iframe.component.scss'],
})
export class Cpt1IframeComponent implements OnInit {
  @Input()
  url: string = '';
  urlSafe: SafeResourceUrl | undefined;
  ssoEnabled: boolean = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public spinner: SpinnerOverlayService,
  ) {
    let iframeURL_Prefix = 'https://' + environment.cpt1Domain;
    let iframeTarget = this.route.snapshot.data['iframeTarget'];
    if (environment.ssoEnabled) {
      this.url = iframeURL_Prefix + '/' + iframeTarget;
    } else {
      this.url = iframeURL_Prefix + '/index.php?gemsRedirect=/' + iframeTarget;
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  iframeload() {
    this.spinner.hide();
  }
}
