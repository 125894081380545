<div class="container">
  <div *ngIf="isEditing && !isNested" fxLayout="row" fxLayoutAlign="space-between none">
    <div style="margin-bottom: 10px;">
      <mat-label *ngIf="label">{{ label }}</mat-label>
    </div>
    <div>
      <span class="color-primary" style="cursor: pointer" (click)="handleAdd()">+ Add {{ addButtonLabel }}</span>
    </div>
  </div>
  <div *ngIf="isEditing; else viewTemplate" cdkDropList [cdkDropListSortingDisabled]="isUpdateOpen()" (cdkDropListDropped)="handleDrop($event)">
    <div *ngFor="let statement of data; let i = index">
      <div class="statement-container" cdkDrag>
        <div class="statement-content">
          <div class="icon-container">
            <mat-icon class="content-icon" matSuffix>drag_indicator</mat-icon>
          </div>
          <mat-checkbox class="content-checkbox" color="primary" [(ngModel)]="statement.selected" (change)="handleSelect($event, i)"></mat-checkbox>
          <p *ngIf="!statement.isEditing" class="content-statement">{{statement.description}}</p>
          <textarea
            *ngIf="statement.isEditing"
            class="content-textarea"
            maxlength="400"
            placeholder="New statement"
            [(ngModel)]="statement.description"
            cdkTextareaAutosize
            matInput
          ></textarea>
          <div *ngIf="statement.isEditing" class="edit-buttons">
            <span class="color-primary edit-action" (click)="handleSave(i)">Save</span>
            <span class="edit-action" (click)="handleCancel(i)">Cancel</span>
          </div>
          <div *ngIf="!statement.isEditing" class="action-buttons">
            <mat-icon class="action-icon" (click)="statement.isEditing=true">edit</mat-icon>
            <mat-icon *ngIf="transferConfigs.length > 0" class="action-icon" [matMenuTriggerFor]="menu" >drive_file_move_outline</mat-icon>
            <mat-menu #menu="matMenu">
              <ng-container *ngFor="let option of transferConfigs">
                <button mat-menu-item (click)="handleTransfer(i, option.key)">
                  <span>Transfer to {{ option.label }}</span>
                </button>
              </ng-container>
            </mat-menu>
          </div>
        </div>
      </div>

      <div style="margin-left:45px;">
        <app-statement-builder *ngIf="statement.selected && statement.children"
          [isEditing]="isEditing"
          [(statements)]="statement.children"
          [isNested]="true"
          (statementsChange)="handleNestedChange()"
        ></app-statement-builder>
      </div>
    </div>
  </div>

  <ng-template #viewTemplate>
    <div class="view-statement-container" *ngFor="let statement of data">
      <div class="statement-content">
        <mat-checkbox disabled color="primary"></mat-checkbox>
        <p class="content-statement">{{statement.description}}</p>
      </div>
    </div>
  </ng-template>
</div>
