<app-dialog [dialogTitle]="title" (onClose)="close()">
  <div>
    <div class="flex-center update-waring-icon" *ngIf="confirmType === 'UPDATE'">
      <img src="assets/icons/warning.svg" alt="" />
    </div>
    <div class="flex-center delete-warning-icon" *ngIf="confirmType === 'DELETE'">
      <img src="assets/icons/error.svg" alt="" />
    </div>
    <div class="flex-center warning-title">{{ confirm }}</div>
    <div class="flex-center warning-sub-title">
      {{ description }}
    </div>
    <div class="flex-center" *ngIf="subDescription">
      {{ subDescription }}
    </div>
  </div>
  <div
    [ngSwitch]="confirmType"
    fxLayout="row"
    fxLayoutGap="16px"
    style="justify-content: space-between; margin-top: 36px"
  >
    <ng-container *ngIf="showCancel">
      <button
        *ngSwitchCase="'CONFIRM_SAVE'"
        fxFlex="50"
        flex
        mat-stroked-button
        class="action-button"
        type="button"
        color="primary"
        (click)="close()"
      >
        {{ cancelButton }}
      </button>
    </ng-container>

    <button
      *ngSwitchCase="'CONFIRM_SAVE'"
      [fxFlex]="showCancel ? 50 : 100"
      mat-flat-button
      class="action-button"
      type="submit"
      [ngClass]="{
        'primary-bg': ['UPDATE', 'CONFIRM_SAVE'].includes(confirmType),
        'warn-bg': confirmType === 'DELETE'
      }"
      (click)="agree()"
    >
      {{ confirmButton }}
    </button>

    <button
      *ngSwitchDefault
      flex
      mat-stroked-button
      class="action-button"
      type="button"
      color="primary"
      (click)="close()"
    >
      {{ cancelButton }}
    </button>
    <button
      *ngSwitchDefault
      mat-flat-button
      class="action-button"
      type="submit"
      [ngClass]="{
        'primary-bg': ['UPDATE', 'CONFIRM_SAVE'].includes(confirmType),
        'warn-bg': confirmType === 'DELETE'
      }"
      (click)="agree()"
    >
      {{ confirmButton }}
    </button>
  </div>
</app-dialog>
