import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customCurrency' })
export class CustomCurrencyPipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    const formattedValue = new Intl.NumberFormat('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (isNaN(Number(value)) && value) {
      return 'Invalid number';
    }

    if (Number(value) < 0) {
      return `($${formattedValue.format(-(value || 0))})`;
    } else {
      return `$${formattedValue.format(+(value || 0))}`;
    }
  }
}
