export interface AllotmentOption {
  sourceOfFunds: Array<{
    id: string;
    actSectionItem: string;
    appropriationCategory: {
      id: string;
      name: string;
    };
    appropriationGroupToJobs:
      | {
          jobId: string;
        }[]
      | null;
  }>;
  jobs: Array<{
    id: string;
    jobName: string;
    jobNumber: string;
    appropriationGroupToJobs: {
      appropriationGroupId: string;
    }[];
    jobCategory: { id: string; jobCategory: string } | null;
  }>;
  projects: Array<{
    id: string;
    projectNumber: string;
    projectName: string;
  }>;
  templates: Array<{
    id: string;
    name: string;
    number: string;
    appropriationCategory: { id: string; name: string };
    job?: {
      id: string;
      jobCategory: { id: string; jobCategory: string } | null;
    } | null;
    createdAt: Date;
  }>;
  allotmentRequestStatuses: Array<string>;
  arMonths: Array<{
    id: string;
    description: string;
    month: string;
  }>;
  schools: Array<{
    id: string;
    name: string;
    jobs: {
      id: string;
    }[];
  }>;
  planners: {
    firstName?: string;
    id: string;
    lastName?: string;
    phoneNumber?: string;
  }[];
}
