export enum TabIndexes {
  ProjectJustification = 0,
  AdditionalInformation = 1,
  PersonsToCC = 2,
}

export enum TabIndexProjectInfo {
  ProjectInformation = 0,
  LocationInformation = 1,
}

export enum TabIndexProjectFunding {
  LegislativeFunding = 0,
  Appropriations = 1,
}

export enum TabIndexLocationModal {
  OverView = 0,
  Demographic = 1,
  TaxMapKey = 2,
  Photo = 3,
}

export enum JOCTabIndexModal {
  OverView = 0,
  JobPO = 1,
  Attachments = 2,
}

export enum AppropriationGroupTabIndex {
  OverView = 0,
  Appropriations = 1,
  Jobs = 2,
  PrimaryJobListing = 3,
}
