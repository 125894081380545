import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  constructor(public router: Router, private route: ActivatedRoute) {
    const target = this.route.snapshot.data['target'];
    let sectionId = '';
    let roleId = '';
    this.route.queryParams.subscribe(params => {
      console.log('redirect params:', params);
      sectionId = params['sectionID'];
      roleId = params['roleID'];
      for (const key in params) {
        localStorage.setItem(key, params[key]);
      }
    });
    this.router.navigate([target]);
    if (roleId === '17') this.router.navigate(['cpt-maint']);
  }

  ngOnInit(): void {}
}
