import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ColorSchemeService } from './global/service/color-scheme.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';

declare var dT_: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ng-core';

  constructor(
    private http: HttpClient,
    private colorSchemeService: ColorSchemeService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    // For Dynatrace instrumentation
    if (typeof dT_ != 'undefined' && dT_.initAngularNg) {
      dT_.initAngularNg(this.http, HttpHeaders);
    }

    // Load Color Scheme
    this.colorSchemeService.load();
  }

  ngOnInit() {
    if (environment.dynatraceSrc) {
      let script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.crossOrigin = 'anonymous';
      script.src = environment.dynatraceSrc;

      this.renderer.appendChild(this.document.body, script);
    }
  }
}
