export interface Milestone {
  id: string;
  comment: string | null;
  milestoneType: string;
  date: string | null;
  milestoneGroupingName: string;
  milestoneName: string;
  milestoneSectionName: string;
  contractMilestoneTypeId: string | null;
  implementationTypeId: string | null;
  implementationType: string | null;
  updatedAt: string;
  updatedBy: string;
  actualStartDate: Date | null;
  plannedDuration: number | null;
  plannedFinishDate: Date | null;
  plannedStartDate: Date | null;
}
