export interface AppropriationGroupsCreateRequest {
  bfy: string;
  act: string;
  section: string;
  item: string;
  appropriationDescription: string;
  mofId: string;
  locationId: string;
  groupTitle: string;
  lapsedDate: string;
  appropriationPriority: number;
  appropriationTypeId: string;
  appropriationSubTypeId: string;
  categoryId: string;
  subcategoryId: string;
  ledgerNumber: string;
  plannerUserId: string;
  budgetProgramId: string;
  capitalProjectNumber: string;
}
