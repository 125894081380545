export interface ContractMonthlyEstimateStatusResponse {
  backGroundColor: string;
  id: string;
  name: string;
  textColor: string;
}

export interface MonthlyEstimate {
  id: string;
  billingMonthId: string;
  billingMonth: string;
  billingYear: number;
  revContractEndDate: string;
  revContractAmount: string;
  compToDate: string;
  amountRetained: string;
  percentageRetained: string;
  amountSubmittedToPayment: string;
  totalPaymentToDate: number;
  paymentDueNow: string;
  workPercentageCompleted: number;
  comment: string;
  paymentNumber: number;
  approvedDate?: string | null;
  status?: ContractMonthlyEstimateStatusResponse;
  procoreId?: string | null;
}

export enum ContractMonthlyEstimateStatusEnum {
  UNDER_REVIEW = 'Under Review',
  REVISE_AND_RESUBMIT = 'Revise and Resubmit',
  APPROVED = 'Approved',
}
