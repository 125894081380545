<div #tableContainer>
  <table style="width: 100%" mat-table matSort matSortDisableClear></table>
</div>

<mat-paginator
  [ngClass]="{ hidden: !showPaginator }"
  [length]="totalRecords"
  [pageSize]="paging.pageSize"
  [pageSizeOptions]="pageSizeOptions"
  showFirstLastButtons
  [hidden]="!showPaginator"
></mat-paginator>
