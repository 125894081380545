export interface AllotmentRequestPendingAndSubmitResponse {
  id: string;
  bfy: string;
  packetBatchMonth: string;
  arSubmittedDate: string;
  jobIncludes: Array<JobIncludeModel>;
}

export interface JobIncludeModel {
  allotmentRequestId: number;
  allotmentStatus: string;
  allotmentSummaryId: string;
  school: string;
  jobName: string;
  jobNo: number;
  parentId: string;
  packetBatchMonth: string;
}
