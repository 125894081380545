import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NumberInputComponent),
    },
  ],
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent implements ControlValueAccessor {
  @Input() isDisabled = false;
  @Input() min: string;
  @Input() max: string;
  @Input() step: number | string;
  @Input() placeholder: string | undefined;
  @Input() readonly: boolean = false;
  @Input() errorMessage: string;
  @Input() touched: boolean;

  public numberValue: number;

  public changed: (value: any) => void = (f: any) => {
    this.numberValue = f;
  };

  // public touched: () => void = () => {};

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(value: number): void {
    if (value || value === 0) {
      this.numberValue = value;
    }
  }

  public onChange(event: any): void {
    if (this.isDisabled) return;
    this.changed(event.target.value);
  }
}
