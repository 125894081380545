import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { formatPhoneNumberInput } from 'app/global/utils/phone';

@Directive({
  selector: 'input[appPhoneNumberFormat]',
})
export class PhoneNumberFormatDirective {
  private _value: string | null;

  constructor(private elementRef: ElementRef<HTMLInputElement>, private control: NgControl) {}

  ngOnInit() {
    this.control.control?.valueChanges.subscribe(value => {
      this._value = formatPhoneNumberInput(value);
      this._onChange(this._value);
    });
  }

  get value(): string | null {
    return this._value;
  }

  @Input('value')
  set value(value: string | null) {
    this._value = value;
    this.formatValue(value);
  }

  private formatValue(value: string | null) {
    if (value || value === '0') {
      this.elementRef.nativeElement.value = formatPhoneNumberInput(value);
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private unFormatValue() {
    const value = this.elementRef.nativeElement.value;
    this._value = formatPhoneNumberInput(value);
    this._onChange(this._value);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this._value = formatPhoneNumberInput(value);
    this._onChange(this._value);
  }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(this._value);
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue();
  }

  _onChange(value: any): void {
    this.elementRef.nativeElement.value = value;
  }

  writeValue(value: any) {
    this._value = value;
    this.formatValue(this._value);
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {}
}
