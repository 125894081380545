export enum ConstructionContractChangeOrdersStatusEnum {
  PENDING = 'Pending - In Review',
  APPROVE = 'Approve',
  REJECT = 'Reject',
}

export interface ConstructionContractChangeOrdersStatus {
  id: string;
  name: string;
  nextStatus?: { id: string; name: string } | null;
  revertStatus?: { id: string; name: string } | null;
  isAutoBySystem?: boolean;
  textColor: string; // Hex code
  backGroundColor: string; // Hex code
}

export interface ConstructionContractChangeOrder {
  id: string;
  changeOrderNumber: string;
  changeOrderInitiatedDate: string;
  changeOrderApprovedDate: string;
  changeOrderAmount: number;
  changeOrderTimeExtension: number;
  changeOrderComment: string;
  status?: ConstructionContractChangeOrdersStatus;
  stat: ConstructionContractChangeOrdersStatusEnum;
  procoreId?: string | null;
}
