export interface AllotmentRequestInformationResponse {
  id: string;
  fiscalMonth: FiscalMonthModel;
  arPriority: number;
  capitalProjectNo: number;
  jobName: string;
  jobNumber: string;
  legislativeTitle: string;
  legislativeDescription: string;
  projectDescription: string;
  a15Statement: string;
  preArPriority: number;
  school: string;
  island: string;
}

export interface FiscalMonthModel {
  id: number;
  description: string;
  externalId?: number;
}
