export interface RfpUnderProtest {
  id: string;
  rfpNo: string;
  rfpName: string;
  protestNumber: string;
  protestor: string;
  protestStatus: string | null;
  statusDate: string | null;
  protestorReceivedDate: string | null;
  certifiedMailNumber: string | null;
  comments: string | null;
}

export interface RfpUnderProtestFilterData {
  rfpNo: string[];
  rfpProtestStatus: { id: string; value: string }[];
}

export interface RfpUnderProtestContent {
  protestSelectionOptions: { value: string; displayLabel: string }[];
  csvFields: { value: string; displayLabel: string }[];
}

export interface RfpUnderProtestActivity {
  rfpProtestId: string;
  rfpProtestStatusId: string;
  description?: string | null;
  actionDate: string;
  from: string;
  to: string;
}

export interface NewRfpUnderProtest {
  rfpNo: string;
  rfpName: string;
  protestor: string;
  comments?: string | null;
}
