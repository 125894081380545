import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss'],
})
export class FilterChipsComponent implements OnInit {
  @Input() selectedFilters: { key: string; label: string; prefix?: string }[] = [
    { key: 'schoolName', label: 'Ahrens Elem', prefix: '' },
  ];
  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  remove(filter: { key: string; label: string }): void {
    const index = this.selectedFilters.indexOf(filter);

    if (index >= 0) {
      this.selectedFilters.splice(index, 1);
    }

    this.onRemove.emit({ removedFilter: filter });
  }
}
