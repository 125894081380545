import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationService } from 'app/global/service/notification.service';
import { filter } from 'rxjs';
import { GetAllApplicationNotificationsResponse } from './../../../global/service/notification.service';

@Component({
  selector: 'app-banner-notification',
  templateUrl: './banner-notification.component.html',
  styleUrls: ['./banner-notification.component.scss'],
})
export class BannerNotificationComponent implements OnInit {
  @Input() background: string = 'linear-gradient(0deg, #FEF2CB, #FEF2CB)';
  @Input() borderColor: string = '#6f550a';
  @Input() textColor: string = '#6f550a';
  messages: string[] = [];
  displayBanner: boolean = false;

  constructor(private notificationService: NotificationService, private router: Router) {}

  ngOnInit(): void {
    this.notificationService
      .getAllApplicationNotifications()
      .subscribe((res: GetAllApplicationNotificationsResponse) => {
        if (!!res.messages?.length) {
          this.displayBanner = true;
          this.messages = res.messages;
        } else {
          this.displayBanner = false;
        }
      });

    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(() => {
      this.notificationService
        .getAllApplicationNotifications()
        .subscribe((res: GetAllApplicationNotificationsResponse) => {
          if (!!res.messages?.length) {
            this.displayBanner = true;
            this.messages = res.messages;
          } else {
            this.displayBanner = false;
          }
        });
    });
  }
}
