export interface PortalTrackerSchoolViewResponse {
  principal: string;
  legislatureHouse: {
    district: string;
    rep: string;
  };
  legislatureSenate: {
    district: string;
    senator: string;
  };
  updatedAt: string;
  complexArea: string;
  asa: string;
  pictureDocumentName: string;
  pictureFileName: string;
  pictureS3Url: string;
}
