export enum AppropriationCostType {
  WORKS_OF_ART = 'WORKS OF ART',
  L_S = 'L/S',
  PLANS = 'PLANS',
  LAND = 'LAND',
  DESIGN = 'DESIGN',
  CONSTRUCTION = 'CONSTRUCTION',
  EQUIPMENT = 'EQUIPMENT',
  ALL = 'ALL',
}
