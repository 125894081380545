import { AllotmentRequestDateResponse } from './allotment-request-date.model';
import { AllotmentRequestFundingResponse } from './allotment-request-funding.model';
import { AllotmentRequestInformationResponse } from './allotment-request-information.model';
import { AllotmentRequestNarrativeResponse } from './allotment-request-narrative.model';

export enum AppropriationCategory {
  LUMP_SUM = 'Lump Sum',
  ADD_ON = 'Add On',
}

export interface AllotmentRequestIdResponse {
  id: string;
  createdAt: string;
  updatedAt: string;
  bfy: string;
  actSectionItem: string;
  plannerFirstName?: string;
  plannerLastName?: string;
  plannerPhoneNumber?: string;
  plannerId?: string;
  jobId?: string;
  status?: string;
  templateJobNo?: string;
  templateJobName?: string;
  templateJobCategory?: string;
  appropriationGroupId: string;
  appropriationCategory: AppropriationCategory;
  allotmentRequestInformation: AllotmentRequestInformationResponse;
  allotmentRequestNarrative: AllotmentRequestNarrativeResponse;
  allotmentRequestFunding: AllotmentRequestFundingResponse;
  allotmentRequestDate: AllotmentRequestDateResponse;
}
