<div
  *ngIf="dropDownPosition === positionEnumCmp.top && isOpen"
  class="dropdown-panel top"
  [ngStyle]="{
    bottom: layout.offsetHeight + 'px'
  }"
>
  <ng-container
    [ngTemplateOutlet]="!!optGroupOptionsField ? groupedDropdown : normalDropdown"
    [ngTemplateOutletContext]="{}"
  ></ng-container>
</div>

<div fxLayout="row" fxLayoutGap="20px" class="full-width">
  <div fxLayout="column" class="full-width">
    <mat-label [ngClass]="{ 'ofo-required': isRequired }">
      {{ _displayLabel }}
    </mat-label>
    <div class="wrapper" (mousedown)="onMouseDown($event)" (clickOutside)="hideFilter($event)" multiSelect #layout>
      <div class="item-container">
        <ng-container *ngIf="selectedItems">
          <div class="" *ngIf="selectedItems.length === 0; else selectedList">
            <div class="value">
              <span class="value-label">All</span>
              <span class="value-icon">×</span>
            </div>
          </div>
        </ng-container>

        <ng-template #selectedList>
          <ng-container *ngIf="selectedItems">
            <div class="value" *ngFor="let item of selectedItems">
              <span class="value-label">
                {{ item?.label }}
              </span>
              <span class="value-icon" (click)="unselect(item)">×</span>
            </div>
          </ng-container>
        </ng-template>

        <form [formGroup]="form" *ngIf="_isSearchable" class="input-container">
          <input formControlName="search" (keyup)="open()" />
        </form>
      </div>

      <div class="" *ngIf="isOpen; else dropdown">
        <mat-icon>expand_more</mat-icon>
      </div>

      <ng-template #dropdown>
        <mat-icon>chevron_right</mat-icon>
      </ng-template>
    </div>
  </div>
</div>

<div *ngIf="dropDownPosition === positionEnumCmp.bottom && isOpen" class="dropdown-panel">
  <ng-container
    [ngTemplateOutlet]="!!optGroupOptionsField ? groupedDropdown : normalDropdown"
    [ngTemplateOutletContext]="{}"
  ></ng-container>
</div>

<ng-template #groupedDropdown>
  <ng-container *ngFor="let item of itemList">
    <ng-container *ngIf="item">
      <div class="grouped-item">
        <div class="header">
          <div class="title">
            <p class="name">{{ item.label }}</p>
            <p class="action cursor" (click)="selectAllItems(item)">Select all</p>
          </div>
          <div class="title">
            <div class="action cursor" (click)="unSelectAllItems(item)">Clear</div>
          </div>
        </div>

        <div class="content">
          <ng-container *ngFor="let child of item.children">
            <mat-checkbox [(ngModel)]="child.selected" (change)="toggleCheckBoxItem(child)">
              {{ child.label }}
            </mat-checkbox>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #normalDropdown>
  <ng-container *ngFor="let item of itemList">
    <ng-container *ngIf="item">
      <div class="option cursor" [class.option-selected]="item.selected" (click)="toggleItem(item)">
        {{ item?.label }}
      </div>
    </ng-container>
  </ng-container>
</ng-template>
