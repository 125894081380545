export interface TransactionResponseById {
  id: string;
  actSectionItem: string;
  groupTitle: string | null;
  appropriations: AppropriationTransactionById[];
}

export interface AppropriationTransactionById {
  id: string;
  appropriationKey: string;
  costType: AppropriationCostType;
  currentBudget: number;
}

interface AppropriationCostType {
  id: number;
  name: string;
  costElements: CostElement[];
}

interface CostElement {
  id: number;
  name: string;
  transactions: Transaction[];
}

interface Transaction {
  amount: number;
  appropriationGroup: AppropriationGroup;
  costElement: TransactionCostElement;
  createdAt: Date;
  externalId: string | null;
  form17Comment: string;
  id: string;
  job: Job;
  matchingTransactionId: string | null;
  transactionType: string;
  user: User;
  appropriationKey: string;
}

interface AppropriationGroup {
  actSectionItem: string;
  id: string;
  groupTitle: string | null;
}

interface TransactionCostElement {
  costType: CostElementCostType;
  id: number;
  name: string;
}

interface CostElementCostType {
  id: number;
  isDeleted: boolean;
  name: string;
  order: number;
}

interface Job {
  id: string;
  jobName: string;
  jobNo: string;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
}
