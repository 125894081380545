import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterControlType } from '../../enum/filter-control-type';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  // host: {
  //   '(document:click)': 'onClick($event)',
  // },
})
export class FilterComponent implements OnInit {
  // @Input() filters: Filter[] = [];

  // @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() outsideClickToClose: boolean;
  @Input() isScrollable: boolean = true;
  @Input() numOfSelectedFilter: number;
  @Output() onApply: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();

  @Input() disableApplyButton: boolean = false;

  FilterControlType = FilterControlType;

  showFilter: boolean = false;

  selected: { [key: string]: any } = {};
  id: string = '';

  constructor(private eref: ElementRef) {}

  ngOnInit(): void {
    this.id = `input-${Math.random() * 1000}`;
    /*
    this.filters.forEach(filter => {
      console.log('filter ngOnInit: filter: ', filter);
      if (filter.getOptions) {
        console.log('filter ngOnInit: if filter.getOptions: ', filter.getOptions);
        filter.getOptions.subscribe((res: FilterOption[]) => {
          if (filter.options) filter.options = filter.options.concat(res);
        });
      }
      const value = localStorage.getItem('filter.' + filter.id);
      const key = filter.id;
      console.log('filter ngOnInit key: ', key, ' value ', value);
      if (value) {
        this.selected[key] = value;
      }
    });

    if (Object.keys(this.selected).length > 0) {
      this.selectionChange.emit(this.selected);
    }
    */
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  hideFilter() {
    if (this.outsideClickToClose) this.showFilter = false;
  }

  clear() {
    // this.toggleFilter();
    this.numOfSelectedFilter = 0;
    this.onClear.emit();
  }

  apply() {
    this.toggleFilter();
    this.onApply.emit();
  }

  showFilterFn(): void {
    this.showFilter = true;
  }
}
