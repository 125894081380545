<div
  #container
  id="image-container"
  (mousedown)="onMouseDown($event)"
  (mousemove)="onMouseMove($event)"
  (mouseup)="onMouseUp($event)"
  (wheel)="onWheel($event)"
>
  <div id="controls">
    <div class="zoom-controls">
      <button mat-icon-button id="increase-image" (click)="zoomIn()"><mat-icon>add</mat-icon></button>
      <button mat-icon-button id="decrease-image" (click)="zoomOut()"><mat-icon>remove</mat-icon></button>
    </div>
  </div>
  <img #image id="image" [src]="src" (load)="onLoadImage()" alt="image" />
</div>
