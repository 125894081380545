export interface AppropriationCreateRequest {
  bfy: string;
  appropriationKey: string;
  appropriationTitle: string;
  withdrawn?: boolean;
  noAllotment?: boolean;
  appropriationDescription?: string;
  locationId: number;
  appropriationAmount: number;
  lapsedDate: string;
  appropriationTypeId: number;
  appropriationSubTypeId?: number;
  act: string;
  section: string;
  item: string;
  groupTitle?: string;
  appropriationPriority?: number;
  mofId: number;
  categoryId: number;
  subcategoryId: number;
  costTypeId: number;
  budgetProgramId?: string;
  comment?: string;
  capitalProjectNumber?: string;
  afmsProgramId?: string;
}
