<div style="display: flex; gap: 10px">
  <span>$</span>
  <input
    type="text"
    matInput
    (change)="onChange($event)"
    [value]="currencyValue"
    [min]="min"
    [max]="max"
    [attr.maxlength]="15"
    [disabled]="disabled"
    [ngStyle]="{ 'text-align': align, 'margin-top': '1px' }"
    placeholder="0.00"
    appCurrencyFormat
  />
</div>
