import { DisplayOption } from '../interface';

export interface LocationOption {
  facilityTypes: Array<DisplayOption>;
  jobDistrictComplexCodes: Array<DisplayOption>;
  legislatureHouses: Array<DisplayOption>;
  districts: Array<DisplayOption>;
  impactFeeDistricts: Array<DisplayOption>;
  complex: Array<DisplayOption>;
  complexAreaNames: Array<DisplayOption>;
  complexAreaCodes: Array<DisplayOption>;
  islands: Array<islandOption>;
  bipsDistricts: Array<DisplayOption>;
  legislatureSenates: Array<DisplayOption>;
  cededLandStatuses: Array<CededLandStatus>;
  csvFields: Array<DisplayOption>;
  schoolTypes: Array<DisplayOption>;
}

export interface CededLandStatus {
  id: string;
  code: string;
  description: string;
  comments: string;
}

export interface islandOption {
  displayLabel: string;
  value: string;
  complexAreas: Array<{
    displayLabel: string;
    value: string;
    complex: Array<DisplayOption>;
    district: DisplayOption;
  }>;
}
