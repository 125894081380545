<input
  type="number"
  [value]="numberValue"
  [placeholder]="placeholder ?? ''"
  [readonly]="readonly"
  [step]="step"
  [min]="min"
  [max]="max"
  [disabled]="isDisabled"
  [ngStyle]="{ border: touched && errorMessage ? '2px solid #f44336' : '1px solid #e0e0e0' }"
  (change)="onChange($event)"
/>
<p *ngIf="touched && errorMessage" style="font-size: 12px; margin-bottom: 0px; margin-top: 5px">
  {{ errorMessage }}
</p>
