import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from 'shared';
import { get, has } from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class ApplicationConfig {
  config: AppConfig | null = null;
  configUrl = '/api/v1/config';

  constructor(private http: HttpClient) {}

  loadConfig(): Observable<AppConfig> {
    return this.http.get(this.configUrl).pipe(map((res: any) => (this.config = res.data)));
  }

  isFeatureEnabled(key: string) {
    const featureFlags = this.config?.featureFlags;
    if (featureFlags && has(featureFlags, key)) {
      return get(featureFlags, key, false);
    }

    console.warn(`Feature Flag: "${key}" does not exist.`);
    return false;
  }
}
