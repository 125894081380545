import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

interface MatSnackBarConfig {
  message: string;
  dismissText?: string;
  duration?: number;
}

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  open({ message, dismissText = 'Dismiss', duration = 5000 }: MatSnackBarConfig) {
    this.snackBar.open(message, dismissText, { duration });
  }
}
