import { FormControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';

export function filterFn(
  listOptions: any[],
  filteredField: string | string[],
  filterCtrl: FormControl,
  filteredListSbj: ReplaySubject<any[]>,
  combinedCharacter?: string, // ' ' or ' - '
) {
  if (!listOptions) {
    return;
  }
  // get the search keyword
  let search = filterCtrl.value;
  if (!search) {
    filteredListSbj.next(listOptions.slice());
    return;
  } else {
    search = search.toLowerCase();
  }

  filteredListSbj.next(
    listOptions.filter((option: any) => {
      if (filteredField) {
        if (typeof filteredField === 'string') {
          return option[filteredField]?.toString().toLowerCase().indexOf(search!) > -1;
        }

        if (Array.isArray(filteredField)) {
          let combinedStr = '';
          for (let key of filteredField) {
            combinedStr = combinedStr.concat(combinedCharacter ?? ' ', option[key]);
          }
          return combinedStr.toString().toLowerCase().indexOf(search!) > -1;
        }

        return;
      } else {
        return option?.toString().toLowerCase().indexOf(search!) > -1;
      }
    }),
  );
}
