import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[multiSelect]',
})
export class MultiSelectDirective {
  @Output() clickOutside = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);

    const clickedOnDropdown =
      targetElement instanceof HTMLElement &&
      (targetElement.className.includes('option') || targetElement.parentElement?.className.includes('option'));

    const clickedOnRemoveItem =
      targetElement instanceof HTMLElement &&
      (targetElement.className.includes('value-icon') || targetElement.parentElement?.className.includes('value-icon'));

    const clickedOngroupedItem =
      targetElement instanceof HTMLElement &&
      (targetElement.className.includes('grouped-item') ||
        targetElement.parentElement?.className.includes('grouped-item') ||
        targetElement.parentElement?.parentElement?.className.includes('grouped-item') ||
        targetElement.parentElement?.parentElement?.parentElement?.className.includes('grouped-item') ||
        targetElement.parentElement?.parentElement?.parentElement?.parentElement?.className.includes('grouped-item'));

    this.clickOutside.emit(!clickedInside && !clickedOnDropdown && !clickedOnRemoveItem && !clickedOngroupedItem);
  }
}
