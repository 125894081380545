import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ApplicationConfig } from 'app/global/config/application.config';

/**
 * Structural directive for showing an element based on if a feature flag is enabled.
 *
 * Usage: *featureFlag="'feature-flag-name'"
 */
@Directive({
  selector: '[featureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  private requiredFlag: string = '';
  @Input() set featureFlag(val: string) {
    if (val) {
      this.requiredFlag = val;
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private appConfig: ApplicationConfig,
  ) {}

  ngOnInit() {
    const isEnabled = this.appConfig.isFeatureEnabled(this.requiredFlag);
    if (isEnabled) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
