import { Component, ElementRef, Input, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { PaneService } from './pane.service';

@Component({
  selector: 'app-pane',
  templateUrl: './pane.component.html',
  styleUrls: ['./pane.component.scss'],
})
export class PaneComponent implements OnInit {
  @Input() position: 'top' | 'bottom' | 'right' | 'left' = 'right';
  @Input() hasVerticalScrollbar: boolean;
  @ViewChild('sidepanel') el: ElementRef | undefined;
  show: boolean = false;

  constructor(private paneService: PaneService, private hostElem: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.paneService.stateChange.subscribe(show => {
      this.show = show;
      const width = show ? '340px' : '0px';

      this.renderer.setStyle(this.el?.nativeElement, 'max-width', width);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasVerticalScrollbar']) {
      this.hasVerticalScrollbar = changes['hasVerticalScrollbar'].currentValue;
    }
  }

  ngOnInit(): void {}

  toggleShow(e: Event) {
    if (this.show) {
      this.paneService.hide();
      return;
    }

    this.paneService.show();
  }
}
