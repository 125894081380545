import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customPercentage' })
export class CustomPercentagePipe implements PipeTransform {
  transform(value: any, ...args: any[]) {
    if (isNaN(Number(value))) {
      return '0.00%';
    }

    const formattedValue = (Number(value) * 100).toFixed(2);

    if (Number(formattedValue) < 0) {
      return `(${Number(formattedValue) * -1}%)`;
    } else {
      return `${formattedValue}%`;
    }
  }
}
