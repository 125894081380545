import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ListService {
  private dataSubject: Subject<void> = new Subject<void>();
  scrollState: boolean = false;

  scrollToTopStateChange = new BehaviorSubject(this.scrollState);

  dataEvent$ = this.dataSubject.asObservable();

  triggerGetData() {
    this.dataSubject.next();
  }

  emitScrollToTopState() {
    this.scrollToTopStateChange.next(this.scrollState);
  }

  scrollToTop() {
    this.scrollState = true;
    this.emitScrollToTopState();
  }
}
