<div class="statement-container">
  <div class="statement-container__drag-indicator">
    <span class="material-icons-outlined statement-container__drag-indicator__icon">drag_indicator</span>
  </div>

  <div
    fxLayout="row"
    class="statement-container__statement-body"
    [ngStyle]="{ width: !isEditing && !isNew ? '80%' : isAgDocsSection ? '65%' : '70%', 'align-items': 'center' }"
  >
    <mat-checkbox
      *ngIf="isAgDocsSection"
      [disabled]="(!isEditing && !isNew) || blockedPacket"
      [checked]="checkboxValue"
      [ngStyle]="{ 'margin-left': !isEditing && !isNew ? '20px' : '10px' }"
      (change)="onChangeCheckbox($event)"
    ></mat-checkbox>

    <span
      style="white-space: pre-wrap"
      *ngIf="!isEditingStatement"
      class="statement-container__statement-body__span"
      [matTooltip]="statement"
      [ngStyle]="{
        left: getLeftStyle(),
        width: isAgDocsSection && (isEditing || isNew) ? (isChildStatement ? '85%' : '90%') : '100%'
      }"
    >
      {{ statement }}
    </span>

    <textarea
      *ngIf="isEditingStatement"
      #statement
      rows="4"
      [placeholder]="isNewlyStatement ? 'New statement ... ' : 'Enter statement...'"
      class="statement-container__statement-body__textarea"
      [ngStyle]="{
        'background-color': !isEditingStatement ? '#EDEFF1' : '',
        border: showRedBorder ? '2px solid #E51F23' : '1px solid #B5BDC5',
        left: getLeftStyle(),
        width: isAgDocsSection && (isEditing || isNew) ? '90%' : '100%'
      }"
      [value]="statement"
    ></textarea>
  </div>

  <ng-container *ngIf="(isEditing || isNew) && !blockedPacket">
    <div class="statement-container__actions">
      <div class="statement-container__actions__btns" *ngIf="isEditingStatement">
        <span style="color: #005fa9; font-weight: 500; cursor: pointer" (click)="onSave()">Save</span>
        <span style="color: #e51f23; font-weight: 500; cursor: pointer" (click)="onCancel()">Cancel</span>
      </div>
      <div class="statement-container__actions__btns" *ngIf="(isEditing || isNew) && !isEditingStatement">
        <span class="material-icons-outlined" style="color: #005fa9; cursor: pointer" (click)="onEdit()">edit</span>
        <ng-container *ngIf="isAgDocsSection && !isChildStatement">
          <span class="material-icons-outlined" style="color: #005fa9; cursor: pointer" (click)="onAdd()">
            add_circle_outline
          </span>
          <span
            class="material-icons-outlined"
            style="color: #005fa9; cursor: pointer"
            mat-button
            [matMenuTriggerFor]="checkedPhasesMenu"
          >
            arrow_circle_right
          </span>
          <mat-menu #checkedPhasesMenu="matMenu" style="max-width: initial !important">
            <ng-container *ngIf="checkedPhases?.length">
              <ng-container *ngFor="let checkedPhase of checkedPhases">
                <button mat-menu-item style="font-weight: 500" (click)="onMove(checkedPhase)">
                  {{ checkedPhase.contractDesignPacketPhase.name }}
                </button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!checkedPhases?.length">
              <div
                fxLayout="column"
                fxLayoutGap="10px"
                mat-menu-item
                style="color: #6d7176; height: 85px; white-space: normal; line-height: 24px"
                disabled
              >
                <span>No available phases.</span>
                <span>Please ensure that your desired phase is checked before moving.</span>
              </div>
            </ng-container>
          </mat-menu>
        </ng-container>
        <span class="material-icons-outlined" style="color: #e51f23; cursor: pointer" (click)="onRemove()">
          delete_forever
        </span>
      </div>
    </div>
  </ng-container>
</div>
