<div
  #sidepanel
  class="sidepanel"
  [ngClass]="position"
  [ngStyle]="{
    right: hasVerticalScrollbar ? '10px' : 0
  }"
>
  <span *ngIf="show" class="material-icons-outlined close-button" (click)="toggleShow($event)">chevron_right</span>
  <span *ngIf="!show" class="material-icons-outlined close-button" (click)="toggleShow($event)">chevron_left</span>
  <div
    class="content"
    [ngStyle]="{
      margin: show ? '0px' : '12px',
      padding: show ? '0px 12px' : '0px'
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
