export interface ContractPSCVendorInfoSummaryResponse {
  pscContractId: string;
  vendorId: string;
  vendorName: string;
  workCapabilities: {
    carpentryProjects: boolean;
    demolitionWork: boolean;
    replaceOldIronPipes: boolean;
    backflowPreventer: boolean;
    installConcreteCurbing: boolean;
    installMetalFencing: boolean;
    repairRoofLeak: boolean;
    paintTwoStoryClassroom: boolean;
    replaceWoodenDeck: boolean;
    clearRootBall: boolean;
    removeAsbestos: boolean;
    replaceBreakerBox: boolean;
    replaceTermiteDamage: boolean;
    replaceDefectiveBells: boolean;
    installSinkCabinetsCounters: boolean;
  };
  additionalInfo: {
    contractPscWorkLocations: [
      {
        id: string;
        contractPscLocationsCapacity: boolean;
        contractPscLocations: {
          id: string;
          locations: boolean;
        };
      },
    ];
    contractPscWorkLicenses: [
      {
        id: string;
        contractPscLicenseType: {
          id: string;
          type: boolean;
        };
      },
    ];
    licenseTypesOther: boolean;
    workHours: boolean;
    inHouseCapacity: boolean;
  };
}
