import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MenuOption } from 'app/shared/interface';
import { Observable, Subject, Subscription, debounceTime, startWith } from 'rxjs';

enum Position {
  top = 'top',
  bottom = 'bottom',
}

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent implements ControlValueAccessor, OnInit {
  @Input() displayLabel: string;
  @Input() isSearchable: boolean;
  @Input() isRequired: boolean = false;
  @Input() onClear: Observable<void>;
  @Input() defaultLabels: string[] = [];
  @Input() optGroupLabelField: string | undefined;
  @Input() optGroupOptionsField: string | undefined;
  @Input() dropDownPosition: string = Position.bottom;
  @Output('onSearchChange') onSearchChange = new EventEmitter<string>();
  @Output('onChangeInit') onChangeInit = new EventEmitter<string>();
  @Output('onChangeValue') onChangeValue = new EventEmitter<string>();
  isRefresh: boolean = false;
  positionEnumCmp = Position;

  searchSubject: Subject<any> = new Subject();

  private subscriptions: Subscription;

  isReady = false;

  _displayLabel: string = '';
  _isSearchable: boolean = false;

  form = new FormGroup({
    search: new FormControl(),
  });

  private _items: any[] = [];
  private _itemsAreUsed: boolean;
  itemList: MenuOption[] = [];
  selectedItems: MenuOption[] = [];

  @Input()
  get items() {
    return this._items;
  }

  set items(value: any[] | null) {
    if (value === null) {
      value = [];
    }
    this._itemsAreUsed = true;
    this._items = value;
  }

  constructor(private _cd: ChangeDetectorRef) {
    this.initAutocompleteSearch();
  }

  ngOnInit() {
    this.isReady = true;
    this.subscriptions = this.onClear.subscribe(() => {
      this.clear();
    });

    setTimeout(() => {
      this.form.controls.search.setValue('');
    }, 2000);

    this.searchSubject.pipe(debounceTime(500)).subscribe(value => {
      this.onSearchChange.emit(value.toLocaleLowerCase());
    });
  }

  clear() {
    this.selectedItems = [];

    this.itemList = this.itemList.map(x => {
      x.selected = !!this.defaultLabels.find(y => y === x.label);
      return x;
    });

    this.itemList.forEach(item => {
      if (!this.selectedItems.find(x => x.value == item.value) && item.selected) {
        this.selectedItems.push(item);
      }
    });

    this.valueChanged();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) {
      this.itemList = changes['items'].currentValue || [];

      this.itemList.forEach(item => {
        if (!this.selectedItems.find(x => x.value == item.value) && item.selected) {
          this.selectedItems.push(item);
        }

        if (item.children?.length && item.children?.length > 0) {
          item.children.forEach(child => {
            if (!this.selectedItems.find(x => x.value == child.value) && child.selected) {
              this.selectedItems.push(child);
            }
          });
        }
      });

      this.valueChanged();
    }

    if (changes['displayLabel'] !== undefined && changes['displayLabel'] !== null) {
      this._displayLabel = changes['displayLabel'].currentValue;
    }

    if (changes['isSearchable'] !== undefined && changes['isSearchable'] !== null) {
      this._isSearchable = changes['isSearchable'].currentValue;
    }
  }

  detectChanges() {
    if (!(<any>this._cd).destroyed) {
      this._cd.detectChanges();
    }
  }

  isOpen: boolean;

  onMouseDown($event: MouseEvent) {
    const target = $event.target as HTMLElement;

    if (target.classList.contains('value-icon')) {
      return;
    }

    this.toggle();
  }

  toggle() {
    if (!this.isOpen && this.itemList?.length > 0) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;
    this.detectChanges();
  }

  close() {
    if (!this.isOpen) {
      return;
    }

    this.isOpen = false;

    this.detectChanges();
  }

  hideFilter(isClose: Boolean) {
    if (isClose) {
      this.isOpen = false;
      if (this._isSearchable && this.selectedItems.length > 0) {
        this.form.controls.search.setValue('');
      }
    }
  }

  select(item: MenuOption) {
    item.selected = true;
    if (!this.selectedItems.find(({ value }) => value === item.value)) {
      this.selectedItems.push(item);
    }
    this.valueChanged();
  }

  toggleItem(item: MenuOption) {
    if (item.selected) {
      this.unselect(item);
    } else {
      this.select(item);
    }
  }

  toggleCheckBoxItem(item: MenuOption) {
    if (item.selected) {
      this.select(item);
    } else {
      this.unselect(item);
    }
  }

  unselect(item: MenuOption) {
    item.selected = false;
    const selectedIndex = this.selectedItems.indexOf(item);
    this.selectedItems.splice(selectedIndex, 1);
    this.valueChanged();
  }

  unSelectAllItems(item: MenuOption) {
    item.children?.forEach(child => this.unselect(child));
  }

  selectAllItems(item: MenuOption) {
    item.children?.forEach(child => this.select(child));
  }

  valueChanged() {
    this.onChange(this.selectedItems.map(x => x.value).join(','));
    this.detectChanges();
    this.onChangeValue.emit(this.selectedItems.map(x => x.value).join(','));
    this.onTouched();
  }

  // CVA implementation

  public onChange = (value: string) => {
    this.onChangeInit.emit(value);
  };
  public onTouched = () => {};

  // register onChange which we will call when the selected value is changed
  // so that the value is passed back to the form model
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // sets the selected value based on the corresponding form model value
  public writeValue(value: any): void {}

  private initAutocompleteSearch(): void {
    this.form.controls.search.valueChanges.pipe(startWith('')).subscribe(value => {
      this.searchSubject.next(value);
    });
  }

  reEmitSearch() {
    this.searchSubject.next(this.form.controls.search.value);
  }
}
