<div style="min-height: 50px; display: flex; justify-content: space-between">
  <div style="display: flex; gap: 30px">
    <div mat-icon-button class="snackbar-success" *ngIf="success; else error">
      <mat-icon style="color: #20ae59; z-index: 1001">check_circle</mat-icon>
    </div>

    <ng-template #error>
      <div mat-icon-button class="snackbar-error">
        <mat-icon style="color: #bb1c21; z-index: 1001">error</mat-icon>
      </div>
    </ng-template>

    <div style="margin: 5px 0">
      <h3 style="margin: 0 0 10px">{{ success ? 'Success' : 'Error' }}</h3>
      <span>{{ message }}</span>
    </div>
  </div>
  <button mat-icon-button (click)="closeSnackbar()">
    <mat-icon>close</mat-icon>
  </button>
</div>
