import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SnackBarService } from 'app/global/service/snack-bar.service';
import { cloneDeep } from 'lodash';
import { StatementType } from '../../enum/statement.enum';
import { Statement, StatementTransfer, TransferConfig } from '../../interface/statement';

@Component({
  selector: 'app-statement-builder',
  templateUrl: './statement-builder.component.html',
  styleUrls: ['./statement-builder.component.scss'],
})
export class StatementBuilderComponent implements OnInit, OnChanges {
  @Input() public isEditing: boolean = false;
  @Input() public isNested: boolean = false;
  @Input() public statements: Statement[] = [];
  @Input() public transferConfigs: TransferConfig[] = [];
  @Input() public label?: String;
  @Input() public addButtonLabel: String = 'statement';
  @Input() public isDeliverable?: boolean = false;
  @Output() statementsChange = new EventEmitter<Statement[]>();
  @Output() statementTransfer = new EventEmitter<StatementTransfer>();

  constructor(private snackBarService: SnackBarService) {}

  data: Statement[] = [];
  type: StatementType;

  ngOnInit(): void {
    this.data = cloneDeep(this.statements);
    this.type = this.isDeliverable ? StatementType.DELIVERABLE : StatementType.TASK;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['statements'] && changes['statements'].currentValue) {
      this.data = cloneDeep(changes['statements'].currentValue);
    }
  }

  isUpdateOpen(): boolean {
    return this.data.map(statement => statement.isEditing).includes(true);
  }

  handleAdd(): void {
    this.data.unshift({ selected: true, description: '', isEditing: true, isNew: true });
    this.statements.unshift({ selected: true, description: '', isEditing: true, isNew: true });
  }

  handleCancel(index: number): void {
    if (this.data[index].isNew) {
      this.data.splice(index, 1);
      this.statements = cloneDeep(this.data);
    } else {
      this.data[index].description = this.statements[index].description;
      this.data[index].isEditing = false;
    }
  }

  handleDrop(event: CdkDragDrop<string[]>) {
    if (this.isUpdateOpen()) {
      this.snackBarService.open({ message: 'Please save/cancel changes before re-ordering statements' });
    } else {
      const newArray = cloneDeep(this.data);
      moveItemInArray(newArray, event.previousIndex, event.currentIndex);
      this.data = newArray;
      this.statementsChange.emit(newArray);
    }
  }

  handleNestedChange(): void {
    this.statements = cloneDeep(this.data);
    this.statementsChange.emit(cloneDeep(this.data));
  }

  handleSave(index: number) {
    delete this.data[index]['isNew'];
    this.statements[index].description = this.data[index].description;
    this.data[index].isEditing = false;
    this.statementsChange.emit(this.statements);
    this.snackBarService.open({ message: `${this.type} has been saved.` });
  }

  handleSelect(event: MatCheckboxChange, index: number): void {
    this.statements[index].selected = event.checked;
    this.statementsChange.emit(cloneDeep(this.data));
  }

  handleTransfer(index: number, key: string): void {
    const statement = this.statements.splice(index, 1).pop();
    this.data.splice(index, 1);
    if (statement != undefined) {
      this.statementTransfer.emit({ key, type: this.type, statement });
    }
  }
}
