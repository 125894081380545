export interface CostElement {
  id: number;
  name: string;
  suggestLapsedAmount: number;
}

interface CostType {
  id: number;
  name: string;
  costElements: CostElement[];
}

export interface Appropriation {
  id: string;
  appropriationKey: string;
  costType: CostType;
  currentBudget: number;
}

export interface SourceOfFundsAmounts {
  id: string;
  actSectionItem: string;
  appropriations: Appropriation[];
}
