export interface ARAmountTotal {
  allotmentRequestTotal?: number;
  remainingFundAvailable?: number;
  appropriationAmount?: number;
  appropriationBalance?: number;
  planTotal?: number;
  landTotal?: number;
  designTotal?: number;
  constructionTotal?: number;
  buildTotal?: number;
  utilityTotal?: number;
  lumpSum?: number;
}
