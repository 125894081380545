import { formatDate } from '@angular/common';
import { Component, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateTimeInputComponent),
    },
  ],
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss']
})
export class DateTimeInputComponent implements ControlValueAccessor {
  @HostListener('input', ['$event.target.valueAsDate']) onChange = (_: any) => {};

  dateTimeValue: string | null;

  constructor(private _elementRef: ElementRef<HTMLInputElement>, private _renderer: Renderer2) { }

  writeValue(dateISOString: string): void {
    const UIValue = formatDate(dateISOString, 'YYYY-MM-dd HH:mm:ss', 'en-IN');

    this._renderer.setAttribute(this._elementRef.nativeElement, 'value', UIValue);
    this.dateTimeValue = UIValue;
  }

  registerOnTouched(fn: any): void {}

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = (value: number) => {
      fn(this.getDate(value).toISOString());
    };
  }

  isValidDate(d: Date | number | null) {
    return d instanceof Date && !isNaN(d as unknown as number);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const date = new Date(control.value);
    return control.value && this.isValidDate(date) ? null : { date: true };
  }

  getDate(value: number) {
    if (value) {
      const date = new Date(value);
      return this.isValidDate(date) ? date : { toISOString: () => null };
    }
    return { toISOString: () => null };
  }
}
