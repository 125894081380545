import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-currency-mat-form-field',
  templateUrl: './currency-mat-form-field.component.html',
  styleUrls: ['./currency-mat-form-field.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: CurrencyMatFormFieldComponent,
    },
  ],
})
export class CurrencyMatFormFieldComponent implements OnInit, MatFormFieldControl<string>, ControlValueAccessor {
  @Input() min: number;
  @Input() max: number;
  @Input() step: string | number;
  @Input() align: string = 'left';
  @Output() onError: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(MatInput, { read: ElementRef, static: true })
  input: ElementRef;

  static nextId = 0;

  @HostBinding()
  id = `currency-mat-form-field-id-${CurrencyMatFormFieldComponent.nextId++}`;

  currencyValue: string;
  errorMessage: string;

  get errorState() {
    return this.errorMatcher.isErrorState(this.ngControl.control as FormControl, null);
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private errorMatcher: ErrorStateMatcher,
    private focusMonitor: FocusMonitor,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange(event: any): void {
    if (this.disabled) return;

    if (event.target.value.toString() === '.') event.target.value = '0';

    if (this.min) {
      if (Number(event.target.value) < this.min) {
        this.errorMessage = `The amount cannot be less than $${this.min}.`;
        event.target.value = null;
      } else if (Number(event.target.value) > this.max) {
        this.errorMessage = `The amount cannot be greater than $${this.max}.`;
        event.target.value = null;
      } else if (event.target.value === '') {
        event.target.value = null;
        this.errorMessage = '';
      } else {
        this.errorMessage = '';
      }

      this.onError.emit(this.errorMessage);
    }

    this.changed(event.target.value);
  }

  public changed: (value: any) => void = (f: any) => {
    this.currencyValue = f;
  };

  public touched: () => void = () => {};

  writeValue(value: number): void {
    if (value) {
      this.currencyValue = value.toString();
    } else {
      this.currencyValue = '';
    }
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  value: string | null;
  stateChanges = new Subject<void>();
  placeholder: string;
  focused: boolean;
  empty: boolean;
  shouldLabelFloat: boolean;
  required: boolean;

  @Input() disabled: boolean = false;

  controlType = 'currency-mat-form-field';

  @HostBinding('attr.aria-describedby') describedBy = '';

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }
  onContainerClick(): void {
    this.focusMonitor.focusVia(this.input, 'program');
  }

  ngOnInit(): void {
    this.focusMonitor.monitor(this.input).subscribe(focused => {
      this.focused = !!focused;
      this.stateChanges.next();
      if (focused === null) {
        this.touched();
      }
    });
  }
}
