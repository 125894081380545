<label class="checkbox-container">
  <input
    class="checkbox-input"
    type="checkbox"
    [ngModel]="checked"
    (ngModelChange)="onModelChange($event)"
    [disabled]="isDisabled"
  />
  <!-- <div class="checkbox-border">
    <div class="checkbox-filling" *ngIf="checked"></div>
  </div> -->
  <label class="custom-text">
    <ng-content></ng-content>
  </label>
</label>
