import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaneService {
  state: boolean = false;
  isClose: boolean = false;

  stateChange = new BehaviorSubject(this.state);
  closeChange = new BehaviorSubject(this.isClose);

  constructor() {}

  emit() {
    this.closeChange.next(this.isClose);
    this.stateChange.next(this.state);
  }

  show() {
    this.isClose = false;
    this.state = true;
    this.emit();
  }

  hide() {
    this.isClose = false;
    this.state = false;
    this.emit();
  }

  close() {
    this.isClose = true;
    this.emit();
  }
}
