export interface AllotmentRequestFundingResponse {
  id: string;
  appropriationAmount: number;
  appropriationBalance: number;
  lumpSum: number;
  planConsultant: number;
  planStaff: number;
  landLand: number;
  landConsultant: number;
  landStaff: number;
  designConsultant: number;
  designStaff: number;
  buildingBid: number;
  buildingAlternates: number;
  buildingContingency: number;
  utilities: number;
  utilitiesContingency: number;
  constructionConsultant: number;
  constructionManagement: number;
  constructionStaff: number;
  woaAmount: number;
  isWoaLess: boolean;
  woaReason: string;
  equipmentAmount: number;
  comments: Array<string>;
  fundingCostDesigns: Array<CostDesignModel>;
  fundingCostConstructions: Array<CostConstructionModel>;
  lumpSumAppropriationKey?: string | null;
  planAppropriationKey?: string | null;
  landAppropriationKey?: string | null;
  designAppropriationKey?: string | null;
  constructionAppropriationKey?: string | null;
  woaAppropriationKey?: string | null;
  equipmentAppropriationKey?: string | null;

  plansPurpose?: string;
  landPurpose?: string;
  designPurpose?: string;
  constructionPurpose?: string;
  equipmentPurpose?: string;
}

export interface CostConstructionModel {
  id?: number;
  locationId: number;
  jobId: number;
  construction: number;
  qualifyingProject: number;
  woa: number;
}

export interface CostDesignModel {
  id?: number;
  location: LocationModel;
  jobId: number;
  design: number;
}

export interface LocationModel {
  id: string;
}
