export interface AllotmentLedgerUpdateRequest {
  amount: number;
  appropriationGroupId: string;
  jobIdSource: string;
  jobIdTarget: string;
  costElementSourceId: number;
  costElementTargetId: number;
  form17Comment: string;
  skipFundError: boolean;
}
