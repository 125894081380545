<div class="form-wrapper" [ngStyle]="{ 'background-color': !isEditing && !isNew ? '#EBEEF1' : '#FFFFFF' }">
  <mat-select
    (valueChange)="onStatusChange($event)"
    [value]="selectedStatus?.id"
    [disabled]="!isEditing && !isNew"
    disableOptionCentering
    panelClass="selectPanelClass"
  >
    <mat-select-trigger>
      <mat-chip-list>
        <mat-chip
          [ngStyle]="{
            color: selectedStatus?.textColor,
            'background-color': selectedStatus?.backGroundColor
          }"
        >
          {{ selectedStatus?.name }}
        </mat-chip>
      </mat-chip-list>
    </mat-select-trigger>

    <ng-container
      *ngIf="flowType === statusFlowTypeEnumCmp.DESIGN_CONTRACT_PACKET_STATUS"
      [ngSwitch]="selectedStatus?.name"
    >
      <ng-container *ngSwitchCase="statusEnumCmp.PC_Draft">
        <mat-option
          *ngIf="selectedStatus?.nextStatus"
          [value]="selectedStatus?.nextStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Next</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.nextStatus?.name)">
                  {{ selectedStatus?.nextStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option
          *ngIf="selectedStatus?.name !== 'Voided'"
          [value]="voidedStatus?.id"
          style="margin: 10px 5px"
          (onSelectionChange)="selectVoided(selectedStatus)"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Void</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(statusEnumCmp.Voided)">Voided</mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option [value]="selectedStatus?.id" style="display: none">
          <mat-chip-list>
            <mat-chip>{{ selectedStatus?.name }}</mat-chip>
          </mat-chip-list>
        </mat-option>
      </ng-container>

      <ng-container *ngSwitchCase="statusEnumCmp.Unit_Head_Review">
        <mat-option
          *ngIf="selectedStatus?.nextStatus"
          [value]="selectedStatus?.nextStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Next</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.nextStatus?.name)">
                  {{ selectedStatus?.nextStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option
          *ngIf="selectedStatus?.revertStatus"
          [value]="selectedStatus?.revertStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Revert</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.revertStatus?.name)">
                  {{ selectedStatus?.revertStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option
          *ngIf="selectedStatus?.name !== 'Voided'"
          [value]="voidedStatus?.id"
          style="margin: 10px 5px"
          (onSelectionChange)="selectVoided(selectedStatus)"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Void</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(statusEnumCmp.Voided)">Voided</mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option [value]="selectedStatus?.id" style="display: none">
          <mat-chip-list>
            <mat-chip>{{ selectedStatus?.name }}</mat-chip>
          </mat-chip-list>
        </mat-option>
      </ng-container>

      <ng-container *ngSwitchCase="statusEnumCmp.FDB_Review">
        <mat-option
          *ngIf="selectedStatus?.nextStatus"
          [value]="selectedStatus?.nextStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Next</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.nextStatus?.name)">
                  {{ selectedStatus?.nextStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option
          *ngIf="selectedStatus?.revertStatus"
          [value]="selectedStatus?.revertStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Revert</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.revertStatus?.name)">
                  {{ selectedStatus?.revertStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option
          *ngIf="selectedStatus?.name !== 'Voided'"
          [value]="voidedStatus?.id"
          style="margin: 10px 5px"
          (onSelectionChange)="selectVoided(selectedStatus)"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Void</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(statusEnumCmp.Voided)">Voided</mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option [value]="selectedStatus?.id" style="display: none">
          <mat-chip-list>
            <mat-chip>{{ selectedStatus?.name }}</mat-chip>
          </mat-chip-list>
        </mat-option>
      </ng-container>

      <ng-container *ngSwitchCase="statusEnumCmp.PCS_Review">
        <mat-option
          *ngIf="selectedStatus?.nextStatus"
          [value]="selectedStatus?.nextStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Next</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.nextStatus?.name)">
                  {{ selectedStatus?.nextStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option
          *ngIf="selectedStatus?.revertStatus"
          [value]="selectedStatus?.revertStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Revert</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.revertStatus?.name)">
                  {{ selectedStatus?.revertStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option
          *ngIf="selectedStatus?.name !== 'Voided'"
          [value]="voidedStatus?.id"
          style="margin: 10px 5px"
          (onSelectionChange)="selectVoided(selectedStatus)"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Void</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(statusEnumCmp.Voided)">Voided</mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option [value]="selectedStatus?.id" style="display: none">
          <mat-chip-list>
            <mat-chip>{{ selectedStatus?.name }}</mat-chip>
          </mat-chip-list>
        </mat-option>
      </ng-container>

      <ng-container *ngSwitchCase="statusEnumCmp.Pending_Approval">
        <mat-option
          *ngIf="selectedStatus?.nextStatus"
          [value]="selectedStatus?.nextStatus?.id"
          style="margin: 10px 5px; background-color: #edeff1"
          [disabled]="true"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">System (Auto)</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.nextStatus?.name)">
                  {{ selectedStatus?.nextStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option
          *ngIf="selectedStatus?.revertStatus"
          [value]="selectedStatus?.revertStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Revert</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.revertStatus?.name)">
                  {{ selectedStatus?.revertStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option
          *ngIf="selectedStatus?.name !== 'Voided'"
          [value]="voidedStatus?.id"
          style="margin: 10px 5px"
          (onSelectionChange)="selectVoided(selectedStatus)"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Void</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(statusEnumCmp.Voided)">Voided</mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option [value]="selectedStatus?.id" style="display: none">
          <mat-chip-list>
            <mat-chip>{{ selectedStatus?.name }}</mat-chip>
          </mat-chip-list>
        </mat-option>
      </ng-container>

      <ng-container *ngSwitchCase="statusEnumCmp.Approved">
        <mat-option
          *ngIf="selectedStatus?.name !== 'Voided'"
          [value]="voidedStatus?.id"
          style="margin: 10px 5px"
          (onSelectionChange)="selectVoided(selectedStatus)"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Void</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(statusEnumCmp.Voided)">Voided</mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option [value]="selectedStatus?.id" style="display: none">
          <mat-chip-list>
            <mat-chip>{{ selectedStatus?.name }}</mat-chip>
          </mat-chip-list>
        </mat-option>
      </ng-container>

      <ng-container *ngSwitchCase="statusEnumCmp.Voided">
        <mat-option
          *ngIf="selectedStatus?.revertStatus && selectedStatus?.revertStatus?.name !== statusEnumCmp.Approved"
          [value]="selectedStatus?.revertStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Revert</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.revertStatus?.name)">
                  {{ selectedStatus?.revertStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option
          *ngIf="selectedStatus?.revertStatus && selectedStatus?.revertStatus?.name === statusEnumCmp.Approved"
          [value]="selectedStatus?.revertStatus?.id"
          style="margin: 10px 5px; background-color: #edeff1"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Revert</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined" style="color: #91979e">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.revertStatus?.name)">
                  {{ selectedStatus?.revertStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option [value]="selectedStatus?.id" style="display: none">
          <mat-chip-list>
            <mat-chip>{{ selectedStatus?.name }}</mat-chip>
          </mat-chip-list>
        </mat-option>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="flowType === statusFlowTypeEnumCmp.MONTHLY_ESTIMATE_STATUS" [ngSwitch]="selectedStatus?.name">
      <ng-container *ngSwitchCase="statusEnumCmp.UNDER_REVIEW">
        <mat-option
          *ngIf="selectedStatus?.rejectStatus"
          [value]="selectedStatus?.rejectStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Reject</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.rejectStatus?.name)">
                  {{ selectedStatus?.rejectStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option
          *ngIf="selectedStatus?.approvedStatus"
          [value]="selectedStatus?.approvedStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Approve</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.approvedStatus?.name)">
                  {{ selectedStatus?.approvedStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option [value]="selectedStatus?.id" style="display: none">
          <mat-chip-list>
            <mat-chip>{{ selectedStatus?.name }}</mat-chip>
          </mat-chip-list>
        </mat-option>
      </ng-container>

      <ng-container *ngSwitchCase="statusEnumCmp.REVISE_AND_RESUBMIT">
        <mat-option
          *ngIf="selectedStatus?.approvedStatus"
          [value]="selectedStatus?.approvedStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Approve</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.approvedStatus?.name)">
                  {{ selectedStatus?.approvedStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option [value]="selectedStatus?.id" style="display: none">
          <mat-chip-list>
            <mat-chip>{{ selectedStatus?.name }}</mat-chip>
          </mat-chip-list>
        </mat-option>
      </ng-container>

      <ng-container *ngSwitchCase="statusEnumCmp.APPROVED">
        <mat-option [value]="selectedStatus?.id" style="display: none">
          <mat-chip-list>
            <mat-chip>{{ selectedStatus?.name }}</mat-chip>
          </mat-chip-list>
        </mat-option>
      </ng-container>
    </ng-container>

    <ng-container
      *ngIf="flowType === statusFlowTypeEnumCmp.CONSTRUCTION_CONTRACT_CHANGE_ORDERS_STATUS"
      [ngSwitch]="selectedStatus?.name"
    >
      <ng-container *ngSwitchCase="statusEnumCmp.PENDING">
        <mat-option
          *ngIf="selectedStatus?.approvedStatus"
          [value]="selectedStatus?.approvedStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Approve</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.approvedStatus?.name)">
                  {{ selectedStatus?.approvedStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>
        <mat-option
          *ngIf="selectedStatus?.rejectStatus"
          [value]="selectedStatus?.rejectStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Reject</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.rejectStatus?.name)">
                  {{ selectedStatus?.rejectStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option [value]="selectedStatus?.id" style="display: none">
          <mat-chip-list>
            <mat-chip>{{ selectedStatus?.name }}</mat-chip>
          </mat-chip-list>
        </mat-option>
      </ng-container>

      <ng-container *ngSwitchCase="statusEnumCmp.REJECT">
        <mat-option
          *ngIf="selectedStatus?.approvedStatus"
          [value]="selectedStatus?.approvedStatus?.id"
          style="margin: 10px 5px"
        >
          <div fxLayout="row" style="justify-content: space-between; align-items: center">
            <span style="font-weight: 500">Approve</span>
            <div fxLayout="row" style="align-items: center; gap: 7px">
              <span class="material-icons-outlined">arrow_forward</span>
              <mat-chip-list>
                <mat-chip [ngStyle]="getTextAndBackgroundColors(selectedStatus?.approvedStatus?.name)">
                  {{ selectedStatus?.approvedStatus?.name }}
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </mat-option>

        <mat-option [value]="selectedStatus?.id" style="display: none">
          <mat-chip-list>
            <mat-chip>{{ selectedStatus?.name }}</mat-chip>
          </mat-chip-list>
        </mat-option>
      </ng-container>

      <ng-container *ngSwitchCase="statusEnumCmp.APPROVE">
        <mat-option [value]="selectedStatus?.id" style="display: none">
          <mat-chip-list>
            <mat-chip>{{ selectedStatus?.name }}</mat-chip>
          </mat-chip-list>
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-select>
</div>
