import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-record-dialog',
  templateUrl: './delete-record-dialog.component.html',
  styleUrls: ['./delete-record-dialog.component.scss'],
})
export class DeleteRecordDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      callBackDelFunction: any;
    },
    private dialogRef: MatDialogRef<DeleteRecordDialogComponent>,
  ) {}

  ngOnInit() {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.close();
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    this.data.callBackDelFunction().subscribe((res: any) => {
      this.dialogRef.close(res);
    });
  }
}
