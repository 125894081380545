<div fxLayout="column" fxLayoutGap="24px" style="padding: 24px 24px 24px 24px; width: 430px">
  <div fxLayout="row" style="align-items: center">
    <div style="padding: 0px 0px 0px 0px">
      <div class="flex">
        <span style="font-size: 18px; font-weight: 600; margin-right: 10px">Delete User</span>
      </div>
    </div>
    <button mat-icon-button style="margin-left: auto" (click)="close()"><mat-icon>close</mat-icon></button>
  </div>
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
    <div fxLayout="column"></div>
    <mat-icon class="error-icon">error</mat-icon>
    <span style="font-weight: 500; margin-top: 20px">Are you sure you would like to delete this user?</span>
    <span style="margin-bottom: 30px">This action cannot be undone.</span>
  </div>
  <div fxLayout="row" fxLayoutGap="16px" style="justify-content: space-between">
    <button mat-stroked-button class="action-button" type="button" color="primary" (click)="close()">Cancel</button>
    <button mat-flat-button class="action-button" type="submit" style="background-color: #bb1c21" (click)="onDelete()">
      Delete
    </button>
  </div>
  <ng-content></ng-content>
</div>
