export interface JOCContractJobPORequest {
  jobId: string;
  appropriationGroupId: string;
  progId?: string;
  poNo: string;
  poDate: string;
  poAmount: number;
  workOrderNo?: string;
  comment: string;
}
