import { CSVOption, ValueOption } from '../interface';
import { JobStatusType, JobUser, Location, mof } from './job.model';

export enum VendorTypeEnum {
  CONSTRUCTION_MANAGEMENT = 'Construction Management',
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  isCm?: boolean;
  isDpc?: boolean;
  isPc?: boolean;
  isPlanner?: boolean;
  isInspector?: boolean;
  isAreaEngineer?: boolean;
  isDeleted?: boolean;
}

export interface ProjectContent {
  id: string;
  projectName: string;
  projectNumber: string;
  jobId?: string;
  mof: {
    id: string;
    name: string;
  };
  implementationType: {
    id: string;
    implementationType: string;
  };
  location: {
    id: string;
    name: string;
    shortName: string;
  };
}

export interface CsRequired {
  id: string;
  description: string;
  isRequiredDiscipline: boolean;
}

export interface CsDiscipline {
  id: string;
  csDisciplineTitle: string;
}

export interface JobPermitStatus {
  id: string;
  jobPermitStatus: string;
}

export interface NifType {
  id: string;
  nifType: string;
  externalId: number;
}

export interface JobContent {
  csvFields: CSVOption[];
  jobCategories: {
    id: string;
    jobCategory: string;
  }[];
  jobPriorities: {
    id: string;
    priorityName: string;
  }[];
  jobPermitStatuses: JobPermitStatus[];
  jobTypes: { id: string; abbreviation: string; jobType: string; jobPriorityId: string }[];
  projects: ProjectContent[];
  users: User[];
  implementationTypes: {
    implementationType: string;
    id: string;
  }[];
  jobStatusTypes: JobStatusType[];
  locations: Location[];
  csRequireds: CsRequired[];
  csDisciplines: CsDiscipline[];
  mofs: mof[];
  planners: JobUser[];
  pcs: JobUser[];
  dpcs: JobUser[];
  areaEngineers: JobUser[];
  inspectors: JobUser[];
  vendors: {
    id: string;
    vendorName: string;
    vendorToVendorTypes: {
      vendorType: {
        vendorType: string;
      };
    }[];
  }[];
  impactFees: Array<ValueOption>;

  islands: Array<ValueOption>;
  complexes: Array<ValueOption>;
  doeDistricts: Array<ValueOption>;
  houseDistricts: {
    id: string;
    district: string;
    rep: string;
  }[];
  senateDistricts: {
    id: string;
    district: string;
    senator: string;
  }[];
  lastMilestone: {
    id: string;
    milestoneName: string;
  }[];
  milestoneGroups: Array<ValueOption>;
  workOrderNumbers: number[];
  jocPermitStatuses: {
    id: string;
    status: string;
  }[];
  nifTypes: NifType[];
  jobActivityLogTypes: { value: string; displayLabel: string }[];
  disasterRecoveries: { id: string; name: string }[];
  jobCohorts: { id: string; name: string }[];
  cmOrganizationAssignments: {
    id: string;
    cmAssignedId: string | null;
    cmOrganizationId: string | null;
  }[];
}

export interface AppropriationGroup {
  id: string;
  bfy: string;
  actSectionItem: string;
  lapsedDate: Date;
  withdrawn?: boolean | null;
  appropriationCategory: boolean;
  appropriationAmount: number;
  allocationAmount: number;
  appropriationGroupId: string;
}
export interface JobRelated {
  id: string;
  school: string;
  jobName: string;
  contractNumber: string;
  dtoAmount: number;
  invoiceAmount: number;
}
