export type PublishedJobBaseline = {
  id: string;
  school: string | null;
  jobNumber: string;
  jobName: string;
  plannedDuration: number | null;
  plannedStartDate: string | null;
  plannedFinishDate: string | null;
  actualStartDate: string | null;
  actualFinishDate: string | null;
};
