import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SpinnerOverlayService } from '../spinner-overlay/spinner-overlay.service';

@Component({
  selector: 'app-cpt1-record-iframe',
  templateUrl: './cpt1-record-iframe.component.html',
  styleUrls: ['./cpt1-record-iframe.component.scss'],
})
export class Cpt1RecordIframeComponent implements OnInit {
  @Input()
  url: string = '';
  urlSafe: SafeResourceUrl | undefined;
  ssoEnabled: boolean = false;
  iframeTarget = '';

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public spinner: SpinnerOverlayService,
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.route.queryParams.subscribe(params => {
      this.iframeTarget = decodeURIComponent(params['page']);
      let iframeURL_Prefix = 'https://' + environment.cpt1Domain;
      if (environment.ssoEnabled) {
        this.url = iframeURL_Prefix + '/' + this.iframeTarget;
      } else {
        this.url = iframeURL_Prefix + '/index.php?gemsRedirect=/' + this.iframeTarget;
      }

      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    });
  }

  iframeload() {
    this.spinner.hide();
  }
}
