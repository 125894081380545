import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    const dropdownMenu = this.elementRef.nativeElement.querySelector('.dropdown-content');
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    const clickedOverlay =
      targetElement instanceof HTMLElement && targetElement.className.includes('cdk-overlay-backdrop');
    const clickedOnDropdown = dropdownMenu && dropdownMenu.contains(targetElement);

    const clickedOnSelect =
      targetElement instanceof HTMLElement &&
      (targetElement.className.includes('mat-select') || targetElement.parentElement?.className.includes('mat-select'));

    const clickedOnAutocomplete =
      targetElement instanceof HTMLElement &&
      (targetElement.className.includes('mat-option') || targetElement.parentElement?.className.includes('mat-option'));

    const clickedValueIcon =
      targetElement instanceof HTMLElement &&
      (targetElement.className.includes('value-icon') || targetElement.parentElement?.className.includes('value-icon'));

    const clickedCalendarDate =
      targetElement instanceof HTMLElement &&
      (targetElement.className.includes('mat-calendar-body-cell') ||
        targetElement.parentElement?.className.includes('mat-calendar-body-cell'));

    const clickedCalendarBtn =
      targetElement instanceof HTMLElement &&
      (targetElement.parentElement?.className.includes('mat-calendar-controls') ||
        targetElement.parentElement?.parentElement?.className.includes('mat-calendar-controls'));

    if (
      !clickedInside &&
      !clickedOnDropdown &&
      !clickedOnSelect &&
      !clickedOnAutocomplete &&
      !clickedValueIcon &&
      !clickedOverlay &&
      !clickedCalendarDate &&
      !clickedCalendarBtn
    ) {
      this.clickOutside.emit(null);
    }
  }
}
