export interface PortalTrackerPSCResponse {
  jobNumber: string;
  jobName: string;
  jobStatus: string;
  dpc?: {
    firstName: string;
    lastName: string;
    email: string;
  };
  totalPoAmount: number;
  vendorContract?: {
    contactName: string;
    email: string;
  };
  vendor: string;
  constructionComplete: string;
  requestForProposals: string;
  projectInitiation: string;
  proposalSelection: string;
  purchaseOrderIssued: string;
  constructionStarted: string;
  preProposalMeeting: string;
}
