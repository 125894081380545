import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BannerClass } from '../../enum/banner-class.enum';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() dialogTitle: string = 'Title';
  @Input() padding: string = '24px 24px 24px 24px';
  @Input() titlePadding: string = '0px 0px 0px 0px';
  @Input() iconName: string = '';
  @Input() editText: string = 'Update';
  @Input() showEditButton: boolean = true;
  @Input() banner: { text: string | undefined; class: BannerClass | undefined };
  @Input() keyIdentifiers: { label: string; value: string; toolTip?: string }[] = [];
  @Input() status: string | undefined;
  @Input() draggable = true;

  @Output() onclickIcon: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor(public dialog: MatDialogRef<any>) {}

  ngOnInit(): void {
    this.dialog.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.onClose.emit();
      }
    });
  }

  close(): void {
    this.onClose.emit();
  }

  handleEventIcon(event: any): void {
    this.onclickIcon.emit();
  }

  ngAfterViewInit(): void {
    document.querySelectorAll('.root-router-outlet-wrapper').forEach((el: any) => {
      el.style.pointerEvents = 'none';
    });

    const cdkOverlayBackdropElement = document.querySelector('.cdk-overlay-backdrop');
    if (cdkOverlayBackdropElement) {
      cdkOverlayBackdropElement.setAttribute('style', 'pointer-events: none !important;');
    }
  }

  ngOnDestroy(): void {
    document.querySelectorAll('.root-router-outlet-wrapper').forEach((el: any) => {
      el.style.pointerEvents = 'auto';
    });
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }
}
