import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { StatusFlow } from '@interface';
import { ContractMonthlyEstimateStatusEnum, DesignContractPacketStatusEnum } from 'app/shared/models';
import { ConstructionContractChangeOrdersStatusEnum } from 'app/shared/models/construction-contract-change-orders-status.model';

export enum FlowType {
  DESIGN_CONTRACT_PACKET_STATUS,
  MONTHLY_ESTIMATE_STATUS,
  CONSTRUCTION_CONTRACT_CHANGE_ORDERS_STATUS,
}

@Component({
  selector: 'app-status-workflow',
  templateUrl: './status-workflow.component.html',
  styleUrls: ['./status-workflow.component.scss'],
})
export class StatusWorkflowComponent implements OnInit, OnChanges {
  @Input() statuses: StatusFlow[] = [];
  @Input() selectedStatus: StatusFlow | null;
  @Input() previousStatus: StatusFlow | null;
  @Input() isEditing: boolean;
  @Input() isNew: boolean;
  @Input() flowType: FlowType;
  @Output() statusSelect: EventEmitter<StatusFlow> = new EventEmitter();
  statusEnumCmp = {
    ...DesignContractPacketStatusEnum,
    ...ContractMonthlyEstimateStatusEnum,
    ...ConstructionContractChangeOrdersStatusEnum,
  };
  statusFlowTypeEnumCmp = FlowType;
  voidedStatus: StatusFlow | null;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['previousStatus']?.currentValue) this.selectVoided(this.previousStatus!);
    if (changes['statuses']?.currentValue)
      this.voidedStatus = this.statuses.find(status => status.name === DesignContractPacketStatusEnum.Voided)!;
  }

  ngOnInit(): void {}

  onStatusChange(value: string): void {
    const prevStatus = this.selectedStatus;
    this.selectedStatus = this.statuses.find(status => status.id === value)!;

    if (this.selectedStatus.name === this.statusEnumCmp.Voided) this.selectVoided(prevStatus);

    this.statusSelect.emit(this.selectedStatus);
  }

  getTextAndBackgroundColors(statusName: string | undefined): { color: string; 'background-color': string } | null {
    if (!statusName) return null;

    const completeStatus = this.statuses?.find(s => s.name === statusName);

    return {
      color: completeStatus?.textColor!,
      'background-color': completeStatus?.backGroundColor!,
    };
  }

  selectVoided(prevStatus: StatusFlow | null): void {
    this.selectedStatus = {
      ...this.selectedStatus!,
      ...(prevStatus && {
        revertStatus: {
          id: prevStatus.id,
          name: prevStatus.name,
        },
      }),
    };
  }
}
