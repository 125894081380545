import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ColorSchemeService {
  private renderer: Renderer2;
  private colorScheme: string;
  private colorSchemePrefix = 'color-scheme-';

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  _getColorScheme() {
    const tenant = localStorage.getItem('selectedTenant');
    this.colorScheme = tenant ? JSON.parse(tenant).name?.toLowerCase() : 'cpt';
  }

  load() {
    this.renderer.removeClass(document.body, this.colorSchemePrefix + this.colorScheme);
    this._getColorScheme();
    this.renderer.addClass(document.body, this.colorSchemePrefix + this.colorScheme);
  }
}
