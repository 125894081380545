export interface PSCContractIdResponse {
  overView: Overview;
  offeror: Offeror;
  workCapabilities: WorkCapabilities;
}

export interface Overview {
  id: string;
  contractNo: string;
  contractId: string;
  status: {
    id: number;
    name: string;
  };
  vendor: {
    id: string;
    vendorName: string;
  };
  coverageArea: string;
  executedDate: string;
  endDate: string;
  maximumAmount: number;
  minimumAmount: number;
  extAllowedPerContract: string;
  comment: string;
  contactName1: string;
  contactEmail1: string;
  contactCompanyPos1: string;
  contactNumber1: string;
  contactName2: string;
  contactEmail2: string;
  contactCompanyPos2: string;
  contactNumber2: string;
}

export interface Offeror {
  contractPscWorkLocations: Array<ContractPscWorkLocations>;
  locationsWorkers: string;
  contractPscWorkLicenses: Array<ContractPscWorkLicenses>;
  licenseDetails: string;
  baseyardCapabilities: string;
  workHours: string;
  licenseTypesOther: string;
}

export interface ContractPscWorkLicenses {
  id: string;
  contractPscLicenseType: {
    id: string;
    type: string;
  };
}

export interface ContractPscWorkLocations {
  id: string;
  contractPscLocationsCapacity: string;
  contractPscLocations: {
    id: string;
    locations: string;
  };
}

export interface WorkCapabilities {
  carpentryProjects: boolean;
  demolitionWork: boolean;
  replaceOldIronPipes: boolean;
  backflowPreventer: boolean;
  installConcreteCurbing: boolean;
  installMetalFencing: boolean;
  repairRoofLeak: boolean;
  paintTwoStoryClassroom: boolean;
  replaceWoodenDeck: boolean;
  clearRootBall: boolean;
  removeAsbestos: boolean;
  replaceBreakerBox: boolean;
  replaceTermiteDamage: boolean;
  replaceDefectiveBells: boolean;
  installSinkCabinetsCounters: boolean;
}
