import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar, MatCalendarUserEvent, MatDatepicker } from '@angular/material/datepicker';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-custom-header-date-picker',
  templateUrl: './custom-header-date-picker.component.html',
  styleUrls: ['./custom-header-date-picker.component.scss'],
})
export class CustomHeaderDatePickerComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _datePicker: MatDatepicker<D>,
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  public todayClicked() {
    const today = new Date();
    this._calendar.activeDate = this._dateAdapter.createDate(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getDate(),
    );
    this._calendar._dateSelected(this._calendar.activeDate as unknown as MatCalendarUserEvent<D>);
    this._datePicker.select(this._calendar.activeDate);
    this._datePicker.close();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
