export interface ProjectGetByIdResponse {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdByUserId: string;
  updatedByUserId: string;
  isDeleted: boolean;
  projectTypeId: string;
  projectCategoryId: string;
  categoryId: string;
  subcategoryId: string;
  projectStatusId: string;
  implementationTypeId: string;
  projectSourceId: string;
  projectPriorityId: string;
  projectMofId: string;
  appropriationGroupId: string;
  statusVoidReasonId: string;
  fundingAssessmentId: string;
  csRequiredId: string;
  unitId: string;
  arBatchMonthId: string;
  jobId: string;
  job: {
    id: string;
    jobName: string;
    jobNumber: string;
    expenditure: number;
    encumbrance: number;
    afmsTotalCost: number;
    jobType: {
      id: string;
      jobType: string;
    };
    jobStatuses: [
      {
        jobStatusType: {
          id: string;
          jobStatusType: string;
        };
      },
    ];
    lastMilestoneLookup: {
      id: string;
      milestoneName: string;
    };
    fmsJobName: string;
    jobMilestones: [
      {
        milestoneDate: string;
        milestoneLookUp: {
          milestoneName: string;
          milestoneNameDisplay: string;
          milestoneSection: {
            name: string;
          };
        };
      },
    ];
    jobUsers: [
      {
        user: {
          id: string;
          firstName: string;
          lastName: string;
        };
      },
    ];
  };
  projectSupplementingJobId: string;
  locationId: string;
  userId: string;
  projectNumber: string;
  projectName: string;
  projectScope: string;
  fyAddedBacklog: string;
  comment: string;
  arPriority: number;
  capitalProjectNumber: string;
  fmpgNumber: string;
  designFundingOnly: true;
  equipmentNeeded: true;
  projectSupplementingComment: string;
  projectEstimatedPlans: string;
  projectEstimatedLand: string;
  projectEstimatedDesign: string;
  projectEstimatedConstruction: string;
  projectEstimatedEquipment: string;
  cdAct: string;
  cdBfy: string;
  cdItem: string;
  legislativeTitle: string;
  legislativeDescription: string;
  appropriationItemTotal: string;
  dateAddedBacklog: string;
  dateOffBacklog: string;
  statusVoidReasonComment: string;
  isMaximoProject: false;
  externalId: string;
  location: {
    id: string;
    name: string;
    island: {
      id: string;
      name: string;
    };
    legislatureSenate: {
      id: string;
      senator: string;
    };
    legislatureHouse: {
      id: string;
      district: string;
      rep: string;
    };
    district: {
      id: string;
      name: string;
    };
    complex: {
      id: string;
      name: string;
    };
  };
  projectStatus: {
    id: string;
    name: string;
    description: string;
  };
  projectType: {
    id: string;
    name: string;
    prefix: string;
    active: true;
    externalId: number;
  };
  projectCategory: {
    id: string;
    code: string;
    description: string;
  };
  projectPriority: {
    id: string;
    projectPriority: string;
    externalId: number;
  };
  projectSource: {
    id: string;
    projectSource: string;
    sortOrder: number;
    externalId: number;
  };
  plannerUser: {
    id: string;
    createdAt: string;
    updatedAt: string;
    firstName: string;
    lastName: string;
    fullName: string;
  };
  implementationType: {
    id: string;
    implementationType: string;
    externalId: 1;
  };
  category: {
    id: string;
    name: string;
  };
  subcategory: {
    id: string;
    name: string;
    appropriationCategoryId: string;
    externalId: string;
  };
  mof: {
    id: string;
    isDeleted: false;
    name: string;
    description: string;
  };
  projectMof?: {
    id: string;
    name: string;
  };
  arBatchMonth: {
    id: string;
    description: string;
    month: string;
    externalId: number;
  };
  unit?: {
    id: string;
    number: number;
    description: string;
    externalId: number;
  };
  fundingAssessment: string;
  statusVoidReason: string;
  appropriationGroup: {
    actSectionItem: string;
    appropriations: [
      {
        id: string;
        appropriationAmount: string;
        appropriationKey: string;
        costType: {
          id: number;
          name: string;
        };
      },
    ];
  };
  csRequired: {
    description: string;
    externalId: number;
    id: string;
    isRequiredDiscipline: boolean;
  };
  projectMaximo?: {
    id: string;
    projectId: string;
    uniformatLevelId: string;
    famProjectScoreId: string;
    famProjectPriorityId: string;
    maximoWorkOrderNumber: string;
    maximoSchool: string;
    maximoBuilding: string;
    maximoRoom: string;
    planYearFy: string;
    externalId: number;
    famProjectPriority: {
      id: string;
      code: number;
      description: string;
      externalId: number;
    };
    famProjectScore: {
      id: string;
      code: number;
      description: string;
      externalId: number;
    };
    uniformatLevel: {
      id: string;
      code: string;
      description: string;
      externalId: number;
    };
  };
  projectSupplementingJob: {
    jobName: string;
    jobNumber: string;
  };
}
