export enum AllotmentRequestStatus {
  ToDo = 'ToDo',
  InProgress = 'InProgress',
  ReadyForBatch = 'ReadyforBatch',
  PendingDOEApproval = 'PendingDOEApproval',
  SubmittedAR = 'SubmittedAR',
}

export const AllotmentRequestStatusEnum = {
  ToDo: 'To Do',
  InProgress: 'In Progress',
  ReadyforBatch: 'Ready for Batch',
  PendingDOEApproval: 'Pending DOE Approval',
  SubmittedAR: 'Submitted AR',
};
