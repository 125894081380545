import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CurrencyComponent),
    },
  ],
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent implements ControlValueAccessor {
  _min?: number;

  @Input() isDisabled = false;
  @Input()
  get min(): number | undefined {
    return this._min;
  }
  set min(value: number | undefined) {
    this._min = value;
    this.errorMessage = '';
    this.onError.emit(this.errorMessage);
    this.changed(this.currencyValue);
  }
  @Input() max: number;
  @Input() step: string | number;
  @Input() align: string = 'left';
  @Input() isError: boolean = false;
  @Output() onError: EventEmitter<string> = new EventEmitter<string>();

  public currencyValue: string;
  errorMessage: string;

  public changed: (value: any) => void = (f: any) => {
    this.currencyValue = f;
  };

  public touched: () => void = () => {};

  public registerOnChange(fn: any): void {
    this.changed = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(value: number): void {
    if (value) {
      this.currencyValue = value.toString();
    } else {
      this.currencyValue = '';
    }
  }

  public onChange(event: any): void {
    if (this.isDisabled) return;

    if (typeof this._min === 'number' && Number(event.target.value) < this._min) {
      this.errorMessage = `The amount cannot be less than $${this._min}.`;
      event.target.value = null;
    } else if (typeof this.max === 'number' && Number(event.target.value) > this.max) {
      this.errorMessage = `The amount cannot be greater than $${this.max}.`;
      event.target.value = null;
    } else if (event.target.value === '') {
      event.target.value = null;
      this.errorMessage = '';
    } else {
      this.errorMessage = '';
    }

    this.onError.emit(this.errorMessage);

    this.changed(event.target.value);
  }

  constructor() {}

  ngOnInit(): void {}
}
