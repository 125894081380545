import { DisplayOption } from '../interface/display-option';

export interface ReportModel {
  id: string;
  name: string;
  description: string;
  url: string;
  reportType: ReportType;
  reportToSecurityResources: { securityResourceId: string }[];
}

export interface ReportType {
  id: string;
  name: 'Private' | 'Public';
}

export interface ReportContent {
  organization: DisplayOption[];
  reportTypes: DisplayOption[];
  roles: DisplayOption[];
  sections: DisplayOption[];
  securityResources: {
    id: string;
    role: {
      id: string;
      name: string;
    };
    organization: {
      id: string;
      name: string;
    };
    section: {
      id: true;
      name: true;
    };
  }[];
}

export interface ReportCreatePayload {
  name: string;
  url: string;
  description: string;
  reportTypeId: string;
  securityResourceIds: string[];
}
