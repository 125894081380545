import {
  ActSectionItemOption,
  DisplayOption,
  JobDisplayOption,
  SchoolDisplayOption,
} from '../interface/display-option';

export interface DesignContractOption {
  statuses: Array<DisplayOption>;
  vendors: Array<DisplayOption>;
  schools: Array<SchoolDisplayOption>;
  jobs: Array<JobDisplayOption>;
  consultants: Array<DisplayOption>;
}

export interface PSCContractOption {
  statuses: Array<DisplayOption>;
  coverageAreas: Array<DisplayOption>;
  vendors: Array<DisplayOption>;
  vendorsFilter: Array<DisplayOption>;
  contractPscLicenseTypes: Array<DisplayOption>;
  jobs: [
    {
      displayLabel: string;
      value: string;
      school: string;
    },
  ];
  schools: Array<PSCJobPOSchoolDisplayOption>;
  lawSectionItems: Array<ActSectionItemOption>;
}

export interface PSCJobPOSchoolDisplayOption {
  displayLabel: string;
  jobs: [
    {
      displayLabel: string;
      value: string;
    },
  ];
  value: string;
}

export interface JOCContractOption {
  statuses: Array<DisplayOption>;
  contractJocTypes: Array<DisplayOption>;
  vendors: Array<DisplayOption>;
  contractJocDistricts: Array<DisplayOption>;
  csvFields: Array<DisplayOption>;
  schools: Array<PSCJobPOSchoolDisplayOption>;
  actSectionItems: Array<PSCJobPOSchoolDisplayOption>;
  vendorsDropdown: DisplayOption[];
}

export interface ConstructionMgmtOption {
  statuses: Array<DisplayOption>;
  schools: Array<SchoolDisplayOption>;
  jobs: Array<JobDisplayOption>;
  cmOrgs: Array<DisplayOption>;
  coverageAreas: Array<DisplayOption>;
  currentActivities: Array<DisplayOption>;
  vendors: Array<DisplayOption>;
  contractActions: Array<DisplayOption>;
}

export interface ContractDwoOption {
  locations: Array<DisplayOption>;
  jobs: Array<DisplayOption>;
  actions: Array<DisplayOption>;
}

export interface DesignOpenEndOption {
  statuses: Array<DisplayOption>;
  schools: Array<SchoolDisplayOption>;
  jobs: Array<JobDisplayOption>;
  designOrganizations: Array<DisplayOption>;
  coverageAreas: Array<DisplayOption>;
  disciplines: Array<DisplayOption>;
  currentActivities: Array<DisplayOption>;
  vendors: Array<DisplayOption>;
  contractActions: Array<DisplayOption>;
}
