import { ValueOption } from '../interface';

export interface JOCContractIdResponse {
  id: string;
  contractId: string;
  contractNo: string;
  contractName: string;
  status: ValueOption;
  vendor: {
    id: string;
    vendorName: string;
  };
  executedDate: string;
  endDate: string;
  extAllowedPerContract: string;
  comment: string;
  contactName1: string;
  contactEmail1: string;
  contactCompanyPos1: string;
  contactName2: string;
  contactEmail2: string;
  contactCompanyPos2: string;
  district: {
    id: string;
    contractJocDistrict: string;
  };
  ifbNumber: string;
  contractType: {
    id: string;
    contractJocType: string;
  };
  pointOfContactName: string;
  pointOfContactPhone: string;
}
