<div (clickOutside)="hideFilter()">
  <button mat-button (click)="toggleFilter()" style="margin-left: 1em">
    <div style="display: flex; gap: 3px">
      Filter
      <div style="display: flex; position: relative">
        <ng-container *ngIf="numOfSelectedFilter">
          <div class="number-filters">{{ numOfSelectedFilter }}</div>
        </ng-container>
        <div>
          <mat-icon style="margin-top: 5px">filter_list</mat-icon>
        </div>
      </div>
    </div>
  </button>
  <div
    [id]="id"
    [ngClass]="isScrollable ? 'dropdown-content scroll' : 'dropdown-content'"
    [ngStyle]="{ display: showFilter ? 'block' : 'none' }"
  >
    <ng-content></ng-content>

    <!--
    <div fxLayout="column" *ngFor="let filter of filters">
      <mat-label *ngIf="filter.controlType === FilterControlType.SELECT">{{ filter.label }}</mat-label>
      <mat-form-field *ngIf="filter.controlType === FilterControlType.SELECT" appearance="outline">
        <mat-select [placeholder]="filter.placeholder ? filter.placeholder : ''" [(ngModel)]="selected[filter.id]">
          <mat-option *ngFor="let option of filter.options" [value]="option.value">{{ option.label }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox
        *ngIf="filter.controlType === FilterControlType.CHECKBOX"
        color="primary"
        [(ngModel)]="selected[filter.id]"
      >
        {{ filter.label }}
      </mat-checkbox>

      <mat-label *ngIf="filter.controlType === FilterControlType.INPUT">{{ filter.label }}</mat-label>
      <mat-form-field
        *ngIf="filter.controlType === FilterControlType.INPUT"
        appearance="outline"
        style="min-width: 200px"
      >
        <input matInput />
      </mat-form-field>
    </div>
    -->

    <div fxLayout="row" fxLayoutGap="16px" style="margin-top: 15px; justify-content: space-between !important">
      <button mat-stroked-button class="action-button" color="primary" (click)="clear()">Clear</button>
      <button mat-flat-button class="action-button" color="primary" (click)="apply()" [disabled]="disableApplyButton">
        <mat-spinner *ngIf="disableApplyButton" diameter="20"></mat-spinner>
        {{ disableApplyButton ? '' : 'Apply' }}
      </button>
    </div>
  </div>
</div>
