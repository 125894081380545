import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Auth } from 'aws-amplify';
import { catchError, Observable, of } from 'rxjs';
import { SpinnerOverlayService } from '../../shared/component/spinner-overlay/spinner-overlay.service';
import { SnackBarService } from './snack-bar.service';

export interface UpdateSelectedTenantPayload {
  tenantId: string;
}

const jwtHelper = new JwtHelperService();
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  loggedIn = false;

  user?: any;

  constructor(
    private http: HttpClient,
    private spinner: SpinnerOverlayService,
    private snackBarService: SnackBarService,
  ) {}

  get token() {
    return localStorage.getItem('token');
  }

  get isAuthenticated() {
    if (this.token) {
      return !jwtHelper.isTokenExpired(this.token);
    } else {
      return false;
    }
  }

  login() {
    this.spinner.show();
    Auth.federatedSignIn({ customProvider: 'Auth0' })
      .then(res => {
        this.spinner.hide();
        this.clearFilters();
        console.log('login:', res);
      })
      .catch(err => {
        this.spinner.hide();
        console.error(err);
      });
  }

  /**
   * Gets all the user attributes and maps the expected values to the identity property for
   * use elsewhere in the application.
   */
  loadUserAttributes(): Observable<any> {
    return of({});
  }

  /**
   * Checks if the user is logged in.
   */
  currentAuthenticatedUser(): Promise<any> {
    return Auth.currentAuthenticatedUser();
  }

  getJwtToken(): Observable<string> {
    return of('jwt-mock');
  }

  changePassword(user: any, oldPassword: string, newPassword: string) {
    Auth.changePassword(user, oldPassword, newPassword);
  }

  async refreshToken() {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken();
  }

  updateUserSelectedTenant(payload: UpdateSelectedTenantPayload) {
    const url = `/api/v1/user-selected-tenant`;
    return this.http.put(url, payload).pipe(
      catchError(err => {
        this.snackBarService.open({ message: 'Unable to switch tenant.' });
        return err;
      }),
    );
  }

  async initiateTokenRefresh() {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const refreshToken = await currentSession.getRefreshToken();
    cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
      window.location.reload();
    });
  }

  clearFilters() {
    Object.keys(localStorage).forEach(key => {
      if (key.includes('filter')) localStorage.removeItem(key);
    });
  }

  clearPageSize() {
    Object.keys(localStorage).forEach(key => {
      if (key.includes('pageSize')) localStorage.removeItem(key);
    });
  }

  clearToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExp');
  }

  logout() {
    // Upon logout, clear filters
    this.clearFilters();
    this.clearPageSize();
    this.clearToken();

    Auth.signOut()
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.error(err);
      });
  }
}
