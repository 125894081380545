import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
const moment = require('moment');

@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.scss'],
})
export class DownloadDialogComponent implements OnInit {
  // @Input() callBackFunction: (args: any) => any;

  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  padding: string = '24px';
  width: string = '348px';
  titlePadding: string = '0px 0px 0px 0px';
  customOptionsList: { optionName: string; optionDisplayLabel: string; toggleOption: boolean }[] = [];
  fileName: string = '';

  toggleCustomOptions = false;

  form: FormGroup;

  currentlySelectedOption: string;
  applyFiltersToggle: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialogRef<DownloadDialogComponent>) {}

  ngOnInit(): void {
    this.customOptionsList = this.data.customOptionsList;
    this.fileName = this.data.fileName ?? '';

    this.form = new FormGroup({
      selectedOption: new FormControl('allData'),
      applyFilters: new FormControl(false),
    });

    this.customOptionsList.forEach((customOption: any) => {
      const addFormControl = new FormControl(customOption.toggleOption);
      this.form.addControl(customOption.optionName, addFormControl);
    });

    this.form.valueChanges.subscribe(form => {
      this.currentlySelectedOption = form.selectedOption;
      this.applyFiltersToggle = form.applyFilters;

      // console.log(this.currentlySelectedOption);
      // console.log(this.applyFiltersToggle);

      // Hide custom checkbox options if "Custom" is not selected.
      if (this.currentlySelectedOption === 'custom') {
        this.toggleCustomOptions = true;
        this.width = this.data.width ? this.data.width : '600px';
      } else {
        this.toggleCustomOptions = false;
        this.width = '348px';
      }
    });
  }

  close() {
    this.dialog.close();
  }

  onSubmit() {
    this.data.callBackFunction(this.form.value).subscribe((res: any) => {
      let binaryData = [];
      binaryData.push(res);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'text/csv' }));

      downloadLink.setAttribute(
        'download',
        `${this.fileName}_${moment(moment.now()).format('YYYYMMDD')}_${moment(moment.now()).format('HHmm')}.csv`,
      );
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
    this.dialog.close();
  }
}
