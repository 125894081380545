export enum DesignContractPacketStatusEnum {
  PC_Draft = 'PC Draft',
  Unit_Head_Review = 'Unit Head Review',
  FDB_Review = 'FDB Review',
  PCS_Review = 'PCS Review',
  Pending_Approval = 'Pending Approval',
  Approved = 'Approved',
  Voided = 'Voided',
}

export interface DesignContractPacketStatus {
  id: string;
  name: string;
  nextStatus?: { id: string; name: string } | null;
  revertStatus?: { id: string; name: string } | null;
  isAutoBySystem?: boolean;
  textColor: string; // Hex code
  backGroundColor: string; // Hex code
}
